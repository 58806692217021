/* Page: 404
--------------------------------------------------------------------------------------------------------------------- */

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  max-width: 600px;
  margin: auto;
  text-align: center;

  @include responsive-down(mobile-large) {
    padding: 50px 0;
    max-width: 500px;
  }

  @include responsive-down(mobile) {
    justify-content: left;
    text-align: left;
    padding: 40px 0;
    align-items: flex-start;
  }

  &__title {
    font-size: 50px;
    color: $primary;
    font-weight: 700;
    margin: 0 0 30px 0;

    @include responsive-down(mobile-large) {
      font-size: 40px;
      margin: 0 0 20px 0;
    }

    @include responsive-down(mobile) {
      font-size: 30px;
      margin: 0;
      text-align: left;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;

    @include responsive-down(mobile-large) {
      font-size: 18px;
      line-height: 22px;
    }

    @include responsive-down(mobile) {
      font-size: 16px;
      line-height: 20px;
    }

    &-small {
      font-weight: 400;
    }
  }

  &__img {
    max-width: 300px;
    margin: 20px 0;

    @include responsive-down(mobile) {
      max-width: 230px;
      margin: 15px 0;
    }
  }

  a {
    .btn--blue {
      &:hover {
        @include responsive-up(tablet-large) {
          color: $white;

          @include transition(all 0.25s ease-in-out);
        }
      }
    }
  }
}
