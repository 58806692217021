/* Layouts: Login
--------------------------------------------------------------------------------------------------------------------- */

.simple {
  padding: 50px 0;

  a {
    word-break: break-word;
  }

  @include responsive-down (tablet-small) {
    padding: 45px 0;
  }

  @include responsive-down (mobile-large) {
    padding: 35px 0;
  }

  &-intro {
    &__title {
      margin: 0;
      font-size: 40px;

      @include responsive-down(tablet-extra-large) {
        font-size: 35px;
      }

      @include responsive-down(tablet) {
        font-size: 30px;
      }

      @include responsive-down(mobile) {
        font-size: 25px;
      }
    }
  }

  &__content {
    h3 {
      font-size: 20px;
      margin: 20px 0 5px;

      @include responsive-down(tablet) {
        font-size: 18px;
      }
    }

    p {
      margin: 8px 0;
    }

    .content-table {
      overflow: scroll;

      table {
        td,
        th {
          min-width: 180px;
          font-size: 14px;
        }
      }
    }
  }
}
