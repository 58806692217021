/* Block: Worker card
--------------------------------------------------------------------------------------------------------------------- */
.workercard {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  box-shadow: $box-shadow-generic;
  width: calc(100% / 4 - 60px);
  height: auto;
  margin-right: 60px;
  margin-bottom: 60px;
  transform: translateY(0);

  @include transition(all 0.5s ease-in-out);

  @include responsive-down (tablet-extra-large) {
    width: calc(100% / 3 - 60px);
  }

  @include responsive-down (tablet) {
    width: calc(100% / 2 - 40px);
    margin-right: 40px;
    margin-bottom: 40px;
  }

  @include responsive-down (mobile) {
    width: calc(100%);
    margin-right: 0;
    margin-bottom: 20px;
  }

  &__img {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 20px;
    min-height: 220px;
    display: inline-flex;
    flex-direction: column;
  }

  &__contentinfo {
    display: inline-flex;
    flex-direction: column;
    margin: 15px 0;
    list-style: none;
  }

  &__title {
    font-weight: 500;
    color: $primary;
    margin: 0 0 auto;
  }

  &__info {
    margin: 5px 0;
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__date {
    font-size: 14px;
    color: $primary-blue;
    margin: auto 0 0;
  }

  &--paragraph {
    min-height: 350px;

    .workercard {
      &__content,
      &__img {
        min-height: initial;
        height: 50%;
        position: relative;

        img {
          transform: scale(1);

          @include transition(all 0.25s ease-in-out);
        }
      }

      &__img {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background: linear-gradient(180deg, #000 0, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 100%);
          z-index: 2;
        }
      }

      &__title {
        margin: auto 0 0;
      }

      &__date {
        margin: 0;
      }

      &__center {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .icon__container {
    display: none;
    position: absolute;
    top: 42%;
    left: 4%;
    z-index: 8;

    i {
      color: $white;
    }
  }

  &--video {
    .icon__container {
      display: block;
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      text-decoration: none;
      transform: translateY(-10px);

      @include transition(all 0.5s ease-in-out);

      .workercard {
        &__img {
          img {
            transform: scale(1.1);

            @include transition(all 0.5s ease-in-out);
          }
        }
      }
    }
  }
}

.contact-book__result {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 60px);
  margin-right: -60px;

  @include responsive-up(desktop-large) {
    max-width: 85%;
    margin: 0 auto;
  }

  @include responsive-down(tablet) {
    width: calc(100% + 40px);
    margin-right: -40px;
  }

  @include responsive-down(mobile) {
    width: 100%;
    margin-right: 0;
  }

  .workercard {
    @include responsive-up(tablet-extra-large) {
      text-decoration: none;
      transform: translateY(0);

      .workercard {
        &__img {
          img {
            transform: scale(1);
          }
        }
      }
    }
  }

  &.contact-book__result--error {
    flex-direction: row;
    justify-content: center;

    i {
      margin-right: 5px;
      color: $warning;
    }
  }
}

.contact-book__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.contact-book__footer {
  margin: 0 0 50px;
}
