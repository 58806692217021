/* Landing: Landing
--------------------------------------------------------------------------------------------------------------------- */
.landing {
  margin-top: 60px;

  @include responsive-down(tablet) {
    margin-top: 50px;
  }

  &--web {
    margin-top: 99px;

    @include responsive-down(tablet) {
      margin-top: 55px;
    }
  }

  .container {
    @media (min-width: 1921px) {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  .section-map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0;
    position: relative;

    @include responsive-down(tablet) {
      flex-direction: column;
    }

    @include responsive-down(mobile) {
      padding: 100px 0 50px;
    }

    &__info {
      width: 400px;

      @include responsive-down(tablet) {
        width: 100%;
      }
    }

    &__title {
      color: $primary;
    }

    &__text {
      font-size: 15px;
    }

    &__list {
      width: calc(100% - 400px);
      padding-left: 275px;
      columns: 2;

      @include responsive-down(tablet-extra-large) {
        padding-left: 100px;
      }

      @include responsive-down(tablet) {
        padding-left: 0;
        width: 100%;
        margin-top: 40px;
      }

      li {
        font-size: 13px;
        line-height: 16px;
        margin: 0 0 25px;

        span {
          display: block;

          &:first-child {
            font-size: 16px;
            color: $primary;
            font-weight: 700;
            margin-bottom: 5px;
          }
        }
      }
    }

    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      svg,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include responsive-down(tablet) {
          width: 80%;
        }
      }
    }
  }

  .section-video {
    position: relative;
    width: 100%;
    height: 550px;
    overflow: hidden;

    @media (min-width: 1921px) {
      height: 600px;
    }

    @include responsive-down(tablet) {
      height: 400px;
    }

    @include responsive-down(mobile) {
      height: 350px;
    }

    &__video,
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__info {
      height: 100%;
    }

    &__title {
      max-width: 350px;
      padding-top: 75px;
      font-size: 28px;
      color: $white;
    }

    .play-video {
      @include responsive-up(tablet) {
        display: none;
      }

      position: absolute;
      top: calc(50% + 25px);
      z-index: 999;
      width: 50px;
      left: calc(50% - 25px);
    }
  }

  .section-comments {
    display: flex;
    flex-direction: column;
    padding: 100px 0;

    @include responsive-down (tablet) {
      padding: 50px 0;
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    &__info {
      max-width: 400px;
    }

    & > .section-comments__info {
      margin: 0 20px;
    }

    &__title {
      color: $primary;
    }

    &__cards {
      display: flex;
      align-items: stretch;
      width: calc(100% + 50px);
      margin: 50px 0 0;

      @include responsive-down (tablet) {
        margin: 25px 0 0;
      }

      @include responsive-down (tablet) {
        margin: 0;
      }

      .landing-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 25px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: $box-shadow-generic;
        padding: 25px;

        &__header {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .stars {
            margin-right: 10px;

            .star {
              width: 10px;
            }
          }
        }

        &__body {
          margin: 15px 0 30px;
          font-size: 14px;
          line-height: 22px;
          font-style: italic;

          @include responsive-down(tablet) {
            margin: 0 0 15px;
          }
        }

        &__footer {
          display: flex;
          align-items: center;

          @include responsive-down(tablet) {
            align-items: flex-start;
          }

          svg {
            width: 18px;
            margin-right: 5px;
          }

          span {
            font-weight: 500;
            font-style: italic;
            color: $primary-blue;
            font-size: 18px;

            @include responsive-down(tablet) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .section-last {
    background: #f7f6f3;
    padding: 100px 0;

    @include responsive-down(tablet) {
      padding: 50px 0;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include responsive-down(tablet) {
        flex-direction: column;
      }
    }

    &__cta {
      width: 200px;

      @include responsive-down(tablet) {
        width: 100%;

        .btn {
          display: none;
        }
      }
    }

    &__title {
      color: $primary;
      margin-bottom: 30px;
      line-height: 26px;

      @include responsive-down(tablet) {
        margin-bottom: 0;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: calc(100% - 200px);
      padding-left: 200px;

      @include responsive-down(tablet-extra-large) {
        padding-left: 50px;
      }

      @include responsive-down(tablet) {
        width: 100%;
        padding-left: 0;
        margin-top: 50px;
      }

      @include responsive-down(tablet-extra-small) {
        flex-direction: column;
      }
    }

    &__column {
      width: calc(100% / 3 - 40px);

      @include responsive-down(tablet-extra-small) {
        width: calc(100% - 40px);
      }

      svg {
        width: 70px;
      }
    }

    &__subtitle {
      color: $primary;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
      margin-top: 10px;
    }

    &__img {
      max-height: 70px;
    }
  }

  .centered-button {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    @include responsive-down(tablet) {
      display: none;
    }
  }

  .leyend-title {
    font-weight: 600;
    color: $primary;
    font-size: 12px;
  }

  .flexible__type-1--small {
    @media (min-width: 1921px) {
      width: 100%;
    }
  }

  .home__itemconsult_img {
    max-height: inherit;
  }

  .flexible__type-6-articles__nav {
    margin: 0 25px;

    i[aria-disabled="true"] {
      color: #dedede;
      cursor: not-allowed;
    }
  }
}

.page-id-28653 {
  .landing {
    .first-section {
      &__container {
        &::before {
          display: none;
        }
      }

      &__h1 {
        color: #67dfdf;
      }

      &__text {
        * {
          color: #222;
        }
      }

      &__form {
        & > div {
          background: #67dfdf;
        }

        .btn--blue {
          background-color: #144689;
        }
      }
    }

    .flexible__type-1-text-header h2 {
      color: #67dfdf;
    }

    .flexible__type-3-content h1,
    .flexible__type-3-content h2,
    .flexible__type-3-content h3,
    .flexible__type-3-content h4,
    .flexible__type-3-content h5,
    .flexible__type-3-content h6 {
      color: #67dfdf;
    }

    .flexible__type-3-items .item__content h1,
    .flexible__type-3-items .item__content h2,
    .flexible__type-3-items .item__content h3,
    .flexible__type-3-items .item__content h4,
    .flexible__type-3-items .item__content h5,
    .flexible__type-3-items .item__content h6 {
      color: #67dfdf;
    }

    .flexible__type-2 h1,
    .flexible__type-2 h2,
    .flexible__type-2 h3,
    .flexible__type-2 h4,
    .flexible__type-2 h5,
    .flexible__type-2 h6 {
      color: #67dfdf;
    }

    .flexible__type-2-cards .card__title {
      color: #67dfdf;
    }

    .flexible__type-15-content h1,
    .flexible__type-15-content h2,
    .flexible__type-15-content h3,
    .flexible__type-15-content h4,
    .flexible__type-15-content h5,
    .flexible__type-15-content h6 {
      color: #67dfdf;
    }

    .flexible__type-6-text h1,
    .flexible__type-6-text h2,
    .flexible__type-6-text h3,
    .flexible__type-6-text h4,
    .flexible__type-6-text h5,
    .flexible__type-6-text h6 {
      color: #67dfdf;
    }

    .flexible__type-6-articles .article__image::before {
      background: #67dfdf;
      opacity: 0.5;
    }
  }
}
