/* Layouts: Search curses
--------------------------------------------------------------------------------------------------------------------- */

.faqs {
  &__intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    padding: 70px 0 40px;

    @include responsive-down(mobile-small) {
      max-width: 100%;
      align-items: flex-start;
      padding: 50px 20px 10px;
    }
  }

  &__introtitle {
    text-align: center;
    color: $primary;
    font-size: 30px;
    margin: 0;
    line-height: 30px;

    @include responsive-down(mobile-extra-large) {
      font-size: 28px;
    }

    @include responsive-down(mobile) {
      font-size: 26px;
    }

    @include responsive-down(mobile-small) {
      text-align: left;
    }
  }

  &__introdescription {
    color: $primary;
    font-size: 15px;
    line-height: 20px;
    margin: 15px 0;
    text-align: center;

    @include responsive-down(mobile-small) {
      text-align: left;
    }
  }

  &__content {
    margin: 0 0 50px;

    @include responsive-down(tablet) {
      margin: 0 0 30px;
    }

    &-category {
      width: 100%;
      padding: 30px 0;

      &--title {
        margin: 0 0 20px;
        color: $primary;
      }

      &__list {
        &-item {
          @include responsive-down(mobile-small) {
            padding: 20px 0 !important;
          }
        }
      }
    }
  }

  .faqs__list-cross {
    top: 3px;
  }
}
