.areasingle {
  &__gallery {
    margin-top: 50px;
    margin-bottom: 50px;

    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      width: 100%;
      margin: 0;

      @include responsive-down(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        figure {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
