/* Layouts: Home
--------------------------------------------------------------------------------------------------------------------- */
.home {
  &__featuredsection {
    display: flex;
    margin: 70px 0 90px;

    @include responsive-down(tablet-small) {
      margin: 50px 0;
    }

    @include responsive-down(mobile-large) {
      margin: 0;
    }

    @include responsive-down(mobile-large) {
      display: block;
      margin: 0 -20px;
    }
  }

  &__itemfeatured {
    position: relative;
    overflow: hidden;
    background-color: transparent;

    @include responsive-down (mobile) {
      padding: 40px 40px 40px 60px;
    }

    p {
      color: $white;

      @include responsive-down(mobile) {
        font-size: 13px;
        line-height: 18px;
      }
    }

    &__img {
      position: absolute;
      width: 20px;
      color: $primary;
      margin: 0 8px 0 0;

      @include responsive-down(tablet) {
        left: 50px;
      }

      @include responsive-down(mobile) {
        width: 25px;
        left: 20px;
        top: 45px;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      color: $primary;
      margin-left: 30px;

      @include responsive-down(tablet) {
        font-size: 19px;
        margin-left: 0 !important;
        padding-top: 30px;
      }

      @include responsive-down(mobile) {
        font-size: 22px;
        line-height: 28px;
        margin-left: 0;
        padding-top: 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .btn {
      margin: 0 0 20px;
    }

    .btn--linewhite {
      margin-bottom: 0;

      @include responsive-down(mobile) {
        margin: 30px 0 0;
      }

      @include responsive-down(mobile-extra-small) {
        margin: 10px 0 0;
      }
    }

    &:nth-child(1) {
      margin: 0 45px 0 0;

      @include responsive-down(tablet) {
        margin: 0 25px 0 0;
      }
    }

    &.box-text-btn {
      padding: 80px 100px;

      @include responsive-down(desktop-large) {
        padding: 40px 50px;
      }

      @include responsive-down(mobile) {
        padding: 15px 20px 30px 70px;
      }
    }

    .js-fade {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background: $services-blue-dark;
        z-index: -1;
        transform: translateX(-125%);
        transition: all 1.2s ease;
      }

      &--active {
        &::before {
          transform: translateX(0);
          transition: all 1.2s ease;
        }
      }
    }

    &:nth-of-type(2) {
      .js-fade {
        &::before {
          background-color: $primary;
          transition-delay: 0.75s;
        }
      }
    }
  }

  &__values {
    background-color: $secondary-light03;
    padding: 90px 0 40px;

    @include responsive-down(tablet-small) {
      padding: 50px 0;
    }

    @include responsive-down(mobile-large) {
      padding: 35px 0 40px;
    }

    .container--medium {
      display: flex;
      justify-content: space-between;

      @include responsive-down(tablet-small) {
        display: block;
      }
    }
  }

  &__valuedescription {
    width: 30%;

    @include responsive-down(tablet) {
      width: 40%;
    }

    @include responsive-down(tablet-small) {
      width: 70%;
      padding: 0 0 40px 0;
    }

    @include responsive-down(mobile-large) {
      width: 100%;
    }

    &--title {
      color: $primary;
      margin: 0 0 35px 0;

      @include responsive-down(mobile-large) {
        font-size: 22px;
        margin: 0 0 22px 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &--subtitle {
      color: $primary;
      font-size: 15px;

      @include responsive-down(mobile-large) {
        font-size: 16px;
      }
    }

    &--content {
      margin: 0 0 42px 0;

      @include responsive-down(mobile-large) {
        margin: 0 0 10px 0;

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    a {
      font-size: 12px;
      letter-spacing: 3px;
      text-transform: uppercase;

      @include responsive-down(mobile-large) {
        font-size: 13px;
      }
    }
  }

  &__valueitems {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include responsive-down(tablet-extra-large) {
      width: 60%;
    }

    @include responsive-down(tablet) {
      padding: 0 0 0 50px;
      width: 70%;
      justify-content: space-around;
    }

    @include responsive-down (tablet-small) {
      padding: 0;
      width: 100%;
      justify-content: space-between;
    }

    @include responsive-down(mobile-extra-large) {
      justify-content: flex-start;
    }

    @include responsive-down(mobile-large) {
      width: 100%;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
    }

    @include responsive-down(mobile) {
      display: inline-block;
      margin: 0 0 0 -5px;
    }

    &__oneitem {
      min-width: 220px;
      margin: 0 50px 40px 0;
      max-width: calc(50% - 80px);
      width: 100%;

      @include responsive-down(tablet-large) {
        margin: 0 30px 30px 0;
        max-width: calc(45% - 40px);
      }

      @include responsive-down(tablet) {
        margin: 0 20px 30px 0;
        max-width: calc(45% - 20px);
        min-width: 210px;

        &:nth-child(2n) {
          margin: 0 0 30px 0;
        }
      }

      @include responsive-down(tablet-small) {
        width: calc((100% / 4) - 20px);
        margin: 0 20px 0 0;
        max-width: none;
        min-width: 0;

        &:nth-child(2n) {
          margin: 0 20px 0 0;
        }

        &:last-child {
          margin: 0;
        }
      }

      @include responsive-down(mobile-extra-large) {
        max-width: calc(70% - 180px);
        width: 100%;
        margin: 0 60px 30px 0;

        &:nth-child(2n) {
          margin: 0 0 30px 0;
        }
      }

      @include responsive-down(mobile) {
        margin: 0 20px 25px 0;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
      }

      &-number {
        display: flex;

        @include responsive-down(mobile) {
          display: flex;
          flex-direction: row-reverse;
          margin: 0 12px 0 0;
          min-width: 100px;
          width: 120px;
          justify-content: flex-end;
        }

        &--cipher {
          font-size: 60px;
          font-weight: 700;
          color: $primary;
          margin: 0 0 24px;
          display: inline-block;

          @include responsive-down(tablet) {
            font-size: 40px;
          }

          @include responsive-down(tablet-small) {
            font-size: 35px;
          }

          @include responsive-down(mobile-large) {
            font-size: 40px;
          }

          @include responsive-down(mobile) {
            font-size: 35px;
            margin: 5px 2px 24px;
          }
        }

        &--img {
          height: 45px;

          @include responsive-down(tablet) {
            height: 30px;
          }

          &.animation {
            height: 45px;
            width: 45px;
            margin-top: -10px;

            @include responsive-down(tablet) {
              height: 30px;
              width: auto;
              margin-top: 0;
            }
          }
        }
      }

      &-text {
        @include responsive-down(mobile) {
          width: calc(100% - 132px);
        }

        &--title {
          color: $primary;
          font-size: 16px;
          margin: 0 0 8px;

          @include responsive-down(mobile) {
            font-size: 14px;
            margin: 0 0 5px;
          }
        }

        &--content {
          font-size: 14px;
          line-height: 20px;
          margin: 5px 0;
        }
      }
    }
  }

  .container--animation {
    position: relative;
    width: calc(100% - 500px);
    min-height: 500px;
    overflow: hidden;

    @include responsive-down(tablet) {
      width: 100%;
      min-height: 250px;
      margin-top: 35px;
    }

    @include responsive-down(mobile) {
      width: 100%;
      min-height: 200px;
      margin-top: 35px;
    }
  }

  &__centersimg {
    width: 100%;
    position: absolute;
    height: 100%;

    @include responsive-down(mobile-large) {
      img {
        width: 350px;
      }
    }

    @include responsive-down(mobile-small) {
      img {
        width: 100%;
      }
    }

    img {
      width: 100%;
      object-fit: contain;
      padding-right: 100px;

      @include responsive-down(tablet) {
        padding-right: 0;
      }
    }
  }

  &__centerspin {
    width: 100%;
    position: absolute;
    height: 100%;
  }

  &__centersinfo {
    padding: 65px 0 0 100px;
    width: 500px;

    @include responsive-down(tablet-extra-large) {
      padding: 0 0 0 50px;
      align-self: center;
      width: 460px;
    }

    @include responsive-down(tablet-large) {
      width: 400px;
    }

    @include responsive-down(tablet-extra-small) {
      padding: 0;
      width: 100%;
      align-self: flex-start;
    }

    @include responsive-down(mobile-large) {
      width: 60%;
    }

    @include responsive-down(mobile-small) {
      width: 100%;
    }

    &--title {
      color: $primary;
      font-size: 24px;
      margin: 0 0 30px 0;

      @include responsive-down(tablet-extra-small) {
        margin: 0 0 16px 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 22px;
      }
    }

    &--subtitle {
      color: $primary;
      font-size: 16px;
    }

    .btn {
      margin: 10px 0 0;

      @include responsive-down(tablet-extra-small) {
        margin: 0;
      }

      i {
        font-size: 8px;
      }
    }
  }

  &__banner {
    margin: 70px 0 70px !important;
    min-height: 330px;

    @include responsive-down(tablet-small) {
      padding: 50px 0;
    }

    @include responsive-down(tablet-extra-small) {
      margin: 50px 0 70px !important;
    }

    @include responsive-down(mobile-large) {
      padding: 0;
    }

    &-content {
      max-width: 455px;

      @include responsive-down(tablet-extra-large) {
        max-width: 460px;
        width: 100% !important;
      }

      @include responsive-down(tablet-extra-large) {
        max-width: 420px;
      }

      @include responsive-down(tablet-small) {
        width: 35% !important;
      }

      @include responsive-down(mobile-large) {
        width: 55% !important;
      }

      @include responsive-down(mobile) {
        width: 100% !important;
      }

      &--title {
        font-size: 30px;

        @include responsive-down(tablet) {
          font-size: 24px;
          width: 240px;
        }

        @include responsive-down(mobile-large) {
          width: 240px;
        }

        @include responsive-down(mobile-extra-small) {
          font-size: 18px;
        }
      }
    }
  }

  &__news {
    &-text {
      &--title {
        font-size: 24px;

        @include responsive-down (mobile) {
          font-size: 22px;
        }

        @include responsive-down (mobile-extra-small) {
          font-size: 18px;
        }
      }
    }
  }
}

body.page-template-page_home {
  .home__news {
    padding: 4em 0;
    margin-bottom: 0;

    @include responsive-down (mobile-large) {
      padding: 1em 0 4em;
    }

    &-index {
      position: relative;

      &::before {
        content: "";
        background-color: #fafafa;
        position: absolute;
        width: 300vw;
        bottom: 0;
        left: -50%;
        height: 100%;
        z-index: -4;
      }
    }
  }
}
