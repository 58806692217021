/* Page: Page Specialities
--------------------------------------------------------------------------------------------------------------------- */
.specialities {
  .flexible {
    &__type {
      &-1 {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary;
        }
      }
    }
  }
}
