/* Page: Single specialities
--------------------------------------------------------------------------------------------------------------------- */
.singlespecialties {
  display: flex;

  @include responsive-down (tablet-extra-small) {
    flex-direction: column-reverse;
    padding: 20px 0;
  }

  .training-single__aside {
    width: 330px;
    margin-right: 20px;
    height: 100%;
    position: inherit;
    top: 180px;
    overflow-x: inherit;

    @include responsive-down(tablet) {
      width: 0;
      padding: 0;
      height: 0;
      position: initial;
    }

    .forms {
      @include responsive-down(tablet) {
        padding: 40px 20px;
      }
    }

    div#form-desktop-js {
      @include responsive-down(tablet) {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999999999;
        height: 100vh;
        display: none;
      }

      & > div {
        @include responsive-down(tablet) {
          height: 100%;
        }
      }

      &.opened {
        @include responsive-down(tablet) {
          display: block;
        }
      }
    }

    &-form {
      padding: 0;

      .forms__group--hidden {
        display: none;
      }

      .btn {
        width: 100%;
        justify-content: center;
        margin: 20px 0 10px;

        &::before,
        &::after {
          background: $white;
        }

        &:focus {
          background-color: $primary-blue;
        }

        &:hover {
          @include responsive-up(tablet) {
            i,
            span {
              color: $primary-blue;
            }
          }
        }
      }
    }

    .training-single__aside-form {
      .form-legal {
        &__text {
          color: $white;
          font-size: 10px;
          line-height: 16px;
          margin-bottom: 0;
        }
      }
    }
  }

  &__form {
    width: 335px;
    margin-right: 20px;
    background-color: $primary;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    height: 100%;
    padding: 40px 20px 20px;

    @include responsive-down (tablet-extra-small) {
      width: 100%;
    }

    &-title {
      color: white;
      font-size: 18px;
    }

    &-checkbox {
      color: white;
      margin: 10px 0;

      a {
        color: white;
        text-decoration: underline;

        @include responsive-down (tablet) {
          font-size: 9px;
        }
      }
    }

    &-legend {
      color: white;
      font-size: 9px;
      line-height: 11px;

      a {
        color: white;
        text-decoration: underline;

        @include responsive-down (tablet) {
          font-size: 9px;
        }
      }
    }

    .mas-customer {
      .mas-customer__group {
        input {
          background-color: white;
        }

        select {
          width: 100%;
          padding: 17px 16px;
          color: $primary;
          font-size: 14px;
          margin: 8px 0;
        }
      }

      button {
        width: 100%;
        margin: 5px 0;
      }
    }

    .forms__common-result {
      p {
        color: $white;
        margin: 0;
        font-size: 14px;
      }
    }

    .forms__group--legal {
      input[type="checkbox"] {
        width: 18px;
        height: 12px;
        border-color: $primary-blue;
        border-radius: 2px;
        background-color: transparent;

        &:checked {
          background: $primary-blue !important;
          background-color: $primary-blue;
        }
      }

      label {
        font-size: 9px;

        a {
          font-size: 9px;
          margin: 0 1.5px;
        }
      }
    }
  }

  &__content {
    width: 70%;
    padding: 0 0 80px 50px;

    @include responsive-down (tablet) {
      padding: 40px 0;
      width: 100%;
    }

    @include responsive-down (tablet-extra-small) {
      padding: 0 0 50px 0;
    }
  }

  &__intro {
    width: 60%;
    padding: 0 0 23px 0;

    @include responsive-down (tablet-large) {
      width: 100%;
    }

    p {
      @include responsive-down (tablet-extra-small) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-title {
      color: $primary;
      font-size: 25px;
      margin: 0 0 20px;

      @include responsive-down (tablet-extra-small) {
        font-size: 22px;
      }
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    border-bottom-left-radius: 0;

    div {
      width: auto;
      height: auto;
      object-fit: cover;
      min-height: 300px;
      min-width: 100%;
      margin-top: -10px;
    }
  }

  &__facts {
    padding: 15px 0 0 0;

    &--item {
      padding: 27px 0 0 0;
      position: relative;

      &_intro {
        display: flex;
        align-items: center;

        img {
          width: 26px;
          display: inline-flex;

          @include responsive-down (mobile-extra-large) {
            width: 24px;
          }
        }

        &--title {
          color: $primary;
          display: inline-block;
          font-size: 16px;
          margin: 0 0 0 15px;

          @include responsive-down (mobile-extra-large) {
            margin: 0 0 0 10px;
          }
        }
      }

      &_content {
        ul {
          margin: 1em 0 1em 1em;
        }
      }
    }
  }
}

.form {
  .mas-customer {
    .form-legal {
      width: 75%;

      @include responsive-down(tablet) {
        width: 100%;
      }

      &__text {
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 0;
      }
    }
  }
}
