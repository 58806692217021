.files {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  margin: 0 -20px 50px;

  @include responsive-down(tablet-extra-small) {
    width: calc(100% + 20px);
    margin: 0 -10px 25px;
  }

  @include responsive-down(mobile-large) {
    margin: 25px -10px;
  }

  .file {
    width: calc(100% / 3 - 40px);
    margin: 20px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    background-color: $white;
    border-radius: 10px;
    box-shadow: $box-shadow-generic;

    @include responsive-down(tablet-large) {
      width: calc(100% / 2 - 40px);
    }

    @include responsive-down(tablet-extra-small) {
      width: calc(100% / 2 - 20px);
      margin: 10px;
    }

    @include responsive-down(mobile-large) {
      width: calc(100% - 20px);
    }

    &__img {
      width: 25%;

      img {
        width: 80%;
      }
    }

    &__text {
      width: 65%;
      color: $black;
      font-weight: 500;
      line-height: 20px;

      @include responsive-down(tablet-extra-large) {
        line-height: 18px;
        font-size: 14px;
      }

      @include responsive-down(tablet-large) {
        line-height: 20px;
        font-size: 16px;
      }

      @include responsive-down(tablet-extra-small) {
        line-height: 18px;
        font-size: 14px;
      }

      @include responsive-down(mobile-large) {
        line-height: 20px;
        font-size: 16px;
      }
    }

    &__download {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;

      i {
        font-size: 30px;
      }
    }

    &:hover {
      text-decoration: none;

      @include responsive-up(tablet-extra-large) {
        transform: translateY(-10px);

        @include transition(all 0.25s ease-in-out);
      }
    }
  }
}
