/* Block: Post-area
--------------------------------------------------------------------------------------------------------------------- */
.postarea {
  display: inline-block;

  &__img {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    width: 100%;
    max-height: 220px;
    height: 180px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include responsive-up (tablet-extra-large) {
        transform: scale(1.05);

        @include transition (all 0.5s ease-in);
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: $black04;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      z-index: 2;
    }

    .icon__container {
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 3;

      i {
        color: $white;
        font-size: 25px;
      }
    }

    &--noshadow {
      &::before {
        opacity: 0.2;
      }
    }
  }

  &__icon {
    position: absolute;
    bottom: 14px;
    left: 14px;
    font-size: 30px;
    color: white;

    &--play {
      font-size: 24px;
    }
  }

  &__category {
    color: $primary-blue;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    margin: 10px 0 0;

    &:hover {
      @include responsive-up(tablet-extra-large) {
        text-decoration: none;
        color: $primary;
      }
    }
  }

  &__title {
    font-size: 18px;
    line-height: 23px;
    color: $primary;
    font-weight: 500;
    margin: 4px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include responsive-down(mobile) {
      line-height: 18px;
      font-size: 14px;
    }

    a {
      color: $primary;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__description {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include responsive-down(mobile) {
      font-size: 13px;
      line-height: 15px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include responsive-down(mobile) {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }

  & > .areahome__link {
    .postarea__img {
      overflow: hidden;

      img {
        transform: scale(1);

        @include transition (all 0.5s ease-in);
      }
    }

    &:hover {
      @include responsive-up(tablet-extra-large) {
        .postarea__img {
          overflow: hidden;

          img {
            transform: scale(1.05);

            @include transition (all 0.5s ease-in);
          }
        }
      }
    }
  }

  .icon-audio,
  .icon-video {
    display: none;
  }

  &--audio {
    .icon-audio {
      display: inline-block;
    }
  }

  &--video {
    .icon-video {
      display: inline-block;
    }
  }

  &:hover {
    text-decoration: none;

    .postarea__title {
      text-decoration: underline;
    }

    .postarea__img {
      img {
        @include responsive-up (tablet-extra-large) {
          transform: scale(1.05);

          @include transition (all 0.5s ease-in);
        }
      }
    }

    @include transition (all 0.2s ease-in);
  }
}
