/* Layouts: Centers
--------------------------------------------------------------------------------------------------------------------- */
.redcenters {
  padding: 64px 0;

  @include responsive-down(mobile) {
    padding: 15px 0 0;
  }

  &__intro {
    width: 100%;

    &--img {
      width: 100%;

      img {
        width: 100%;
        height: 430px;
        object-fit: cover;
        border-radius: 12px;
        border-bottom-left-radius: 0;
      }
    }

    &--title {
      text-align: center;
      color: $primary;
      font-size: 30px;
      margin: 0;
      padding: 0 0 50px;

      @include responsive-down(mobile) {
        margin: 15px 0;
        padding: 0;
        font-size: 25px;
        text-align: left;
      }
    }

    .map {
      border-radius: 12px;
      border-bottom-left-radius: 0;

      &__window {
        padding: 7.5px;
      }

      &__title {
        color: $primary;
      }

      &__text {
        font-size: 14px;
        margin: 0;

        &:nth-child(4) {
          color: $primary;
          font-weight: 600;
        }

        &:nth-child(5) {
          color: $primary;
          font-weight: 500;
        }

        &--latest {
          margin-bottom: 20px;
        }
      }

      .gm-ui-hover-effect {
        top: 0 !important;
        right: 0 !important;
      }
    }
  }

  &__searcher {
    padding: 15px 190px;

    @include responsive-down(tablet) {
      padding: 0 150px 20px;
    }

    @include responsive-down(tablet) {
      padding: 0 100px 20px;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 0 80px 20px;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 0;
    }

    &-intro {
      text-align: center;
      font-size: 14px;
      margin: 10px 0;

      @include responsive-down(mobile) {
        text-align: left;
        margin-top: 30px;
        line-height: 16px;
      }
    }

    &-form {
      position: relative;

      .btn {
        position: absolute;
        right: 0;
        top: 8px;
        padding: 18px 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .redcenters__all {
      border: none;
      background: none;
      text-decoration: underline;
      color: $primary-blue;
      text-transform: uppercase;
      margin-top: 25px;
      font-size: 14px;
      letter-spacing: 2px;
      text-align: center;
      width: 100%;
      cursor: pointer;
      display: block;

      @include responsive-down(tablet) {
        margin-bottom: 60px;
      }

      &:hover {
        @include responsive-up(tablet) {
          text-decoration: none;

          @include transition (all 0.2s ease-in);
        }
      }
    }
  }

  &__found {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: baseline;
    padding: 5px 0;
    width: 100%;
    margin-bottom: 30px;

    @include responsive-down(mobile) {
      display: block;
      margin-bottom: 15px;
    }

    p {
      margin: 0;

      @include responsive-down(mobile-large) {
        font-size: 12px;
      }
    }

    .link {
      font-size: 11px;
      margin-left: 5px;

      @include responsive-down(mobile-large) {
        font-size: 9px;
      }
    }

    &-number {
      font-size: 14px;
    }
  }

  &__itemresult {
    width: calc((100% / 3) - 30px);
    background-color: $white;
    box-shadow: $box-shadow-generic;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    margin: 0 30px 30px 0;
    min-height: 175px;
    display: flex;

    @include responsive-down(tablet-extra-large) {
      width: calc((100% / 2) - 30px);
      margin: 0 30px 30px 0;
    }

    @include responsive-down(tablet) {
      width: calc((100% / 2) - 20px);
      margin: 0 20px 20px 0;
    }

    @include responsive-down(tablet-extra-small) {
      width: 100%;
      margin: 0 0 20px 0;
      min-height: 170px;
    }

    &--img {
      width: 146px;
      height: 100%;
      object-fit: cover;
      background-color: $secondary-light03;
      border-top-left-radius: 10px;
      position: relative;
      overflow: hidden;

      @include responsive-down(mobile-small) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .subsidised {
        position: absolute;
        margin: 7px auto;
        bottom: 0;
        text-align: center;
        right: 0;
        left: 0;
        text-transform: uppercase;
        color: $primary;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 10px;
        z-index: 8;

        @include responsive-down(tablet-extra-large) {
          font-size: 8px;
        }
      }
    }

    &--concertwithoutimg {
      .redcenters--content--img {
        &::before {
          content: "";
          width: 100%;
          height: 50px;
          position: absolute;
          bottom: 0;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(195, 231, 255, 1) 100%);
          z-index: 7;
        }
      }
    }

    &--concertwithimg {
      .subsidised {
        color: white;
      }
    }

    &--dontconcert {
      .subsidised {
        display: none;
      }
    }

    &--content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      font-style: normal;
      align-items: flex-start;
      width: calc(100% - 146px);

      .title {
        color: $primary;
        margin: 0 0 8px;
      }

      &-info {
        padding: 0 0 0 18px;

        .direction {
          position: relative;
          margin: 0 0 8px;

          i {
            font-size: 15px;
          }
        }

        .postal-code {
          margin: -6px 0 10px;
        }

        .email,
        .phone {
          margin: 5px 0;
          position: relative;

          i {
            font-size: 13px;
          }
        }

        p {
          font-size: 11px;
          line-height: 15px;
        }

        i {
          color: $primary;
          position: absolute;
          left: -20px;
          top: 1px;
        }
      }

      .link {
        font-size: 10px;
        margin: auto 0 0;
        display: inline-block;
        padding-top: 10px;
      }
    }

    .title-link {
      &:hover {
        @include responsive-up(tablet) {
          text-decoration: none;

          h3 {
            color: $primary-blue;
          }
        }
      }
    }

    .img-link {
      overflow: hidden;

      img {
        transform: scale(1);
        transition: all 0.5s ease;
      }

      &:hover {
        @include responsive-up(tablet) {
          img {
            transform: scale(1.2);
            transition: all 0.5s ease;
          }
        }
      }
    }
  }

  &__content {
    width: calc(100% + 30px);
    display: flex;
    flex-wrap: wrap;

    @include responsive-down(tablet) {
      width: 100%;
      padding-bottom: 30px;
    }
  }

  &__results {
    margin-top: 0;

    @include responsive-down(tablet) {
      margin-top: -50px;
    }

    &:empty {
      margin-top: 0;
    }

    & > strong {
      width: 100%;
      display: block;
      margin: 0 auto;
      text-align: center;
      margin-top: 80px;
      font-size: 14px;
      color: $primary;

      @include responsive-down(tablet) {
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }

  &__intromap {
    position: relative;
  }

  &__msgmap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    overflow: hidden;
    opacity: 1;
    visibility: visible;

    @include transition(all 0.2s ease-in);

    &--hidden {
      opacity: 0;
      visibility: hidden;

      @include transition(all 0.2s ease-in);
    }
  }

  &__imgmaphidden {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

    &::before {
      content: "";
      backdrop-filter: saturate(100%) blur(4px);
      background: rgba(20, 71, 137, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__titlemap {
    font-size: 20px;
    padding: 0 50px;
    position: absolute;
    margin: 0;
    color: $white;
    font-weight: 500;
    max-width: 470px;
    text-align: center;

    @include responsive-down(mobile-extra-large) {
      font-size: 16px;
    }
  }
}

.center {
  &__intro {
    &-title {
      font-size: 20px;
      font-weight: 700;
      color: $primary;
      padding: 40px 0;

      @include responsive-down (tablet-extra-small) {
        padding: 50px 0 0;
      }

      @include responsive-down(tablet-extra-small) {
        padding: 10px 0 0;
        font-size: 16px;
      }
    }
  }

  &__content {
    display: flex;
    margin-bottom: 25px;

    @include responsive-down(tablet-extra-small) {
      display: block;
    }

    &-img {
      object-fit: cover;
      height: 310px;
      width: 950px;
      position: relative;

      @include responsive-down(desktop-extra-small) {
        width: 850px;
      }

      @include responsive-down (tablet-extra-large) {
        width: 50%;
      }

      @include responsive-down (tablet-extra-small) {
        width: 100%;
      }

      @include responsive-down (mobile-large) {
        height: 250px;
      }

      @include responsive-down (mobile-small) {
        height: 200px;
      }

      &::before {
        content: "";
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(195, 231, 255, 1) 100%);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .subsidised {
        position: absolute;
        margin: 7px auto;
        bottom: 0;
        text-align: center;
        right: 0;
        left: 0;
        text-transform: uppercase;
        color: $primary;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    &-info {
      padding: 75px;
      width: 100%;
      font-style: normal;

      @include responsive-down(desktop-extra-small) {
        padding: 65px;
      }

      @include responsive-down (tablet-extra-large) {
        padding: 10px 50px 0;
        width: 50%;
      }

      @include responsive-down(tablet-extra-small) {
        padding: 40px 0 0;
        width: 100%;
      }

      &--city {
        font-size: 28px;
        font-weight: 700;
        color: $primary;

        @include responsive-down(tablet-extra-small) {
          font-size: 24px;
        }

        @include responsive-down(mobile-extra-small) {
          font-size: 20px;
        }
      }

      &--street {
        display: flex;

        @include responsive-down (tablet-extra-large) {
          display: block;
        }

        @include responsive-down(tablet-extra-small) {
          display: flex;
          width: 100%;
        }

        @include responsive-down(mobile-large) {
          display: block;
        }

        &__direction {
          padding: 0 0 0 30px;
          margin: 0 70px 0 0;

          @include responsive-down(mobile) {
            margin: 0;
          }

          p {
            position: relative;
            margin: 23px 0 0;

            @include responsive-down(mobile-large) {
              margin: 15px 0 0;
            }

            @include responsive-down(mobile-extra-small) {
              font-size: 14px;
            }
          }

          a {
            color: $black;
          }

          i {
            font-size: 22px;
            color: $primary;
            position: absolute;
            left: -30px;

            @include responsive-down(mobile-extra-small) {
              font-size: 20px;
            }
          }

          .postal-code {
            margin: 0;
          }
        }

        &__data {
          margin: 16px 0 0;

          @include responsive-down (tablet-extra-large) {
            margin: 30px 0 0;
          }

          p {
            font-weight: 700;
            color: $primary;
            margin: 0;
            font-size: 15px;
          }

          .btn {
            padding: 10px 15px;
            margin: 20px 0;

            i {
              font-size: 20px;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
