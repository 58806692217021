/* Page: Health
--------------------------------------------------------------------------------------------------------------------- */
.health {
  .flexible {
    &__type {
      &-0 {
        p {
          color: $primary;
        }
      }

      &-health {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 40px);
        margin: 3em -20px;

        &__card {
          width: calc(100% / 2 - 40px);
          margin: 20px;
          padding: 45px;
          box-shadow: $box-shadow-generic;
          border-radius: 5px;
          border-bottom-left-radius: 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          @include responsive-down(mobile-large) {
            width: calc(100% - 40px);
          }

          .title {
            color: $primary;
          }

          img {
            width: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
