/* Block: Next post area
--------------------------------------------------------------------------------------------------------------------- */
.areanextpost {
  display: inline-flex;
  padding: 10px;
  height: 90px;
  border-radius: 10px;

  &__img {
    border-radius: 10px;
    height: auto;
    margin: 0 10px 0 0;
    width: 100px;

    img {
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title,
  &__date {
    margin: 0;
  }

  &__title {
    font-size: 14px;
  }

  &__date {
    font-size: 12px;
    font-style: italic;
    color: $secondary-grey;
  }

  &:hover {
    background-color: $light-grey;

    @include transition (all 0.2s ease-in);
  }
}
