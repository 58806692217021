/* Partials: Footer
--------------------------------------------------------------------------------------------------------------------- */
.footer {
  display: block;
  background-color: $secondary-dark;
  padding: 64px 0 20px;
  color: $white;
  position: relative;

  @include responsive-down(mobile-extra-large) {
    padding: 44px 0 5px;
  }

  .go-top {
    position: absolute;
    right: 50px;
    bottom: 46px;

    @include responsive-down(tablet) {
      right: 15px;
    }

    @include responsive-down(mobile-extra-large) {
      bottom: 26px;
    }

    i {
      display: inline-block;
      background: $white;
      color: $primary;
      border-radius: 50%;
      padding: 10px;
      font-size: 15px;
      box-shadow: $box-shadow-generic;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transform-origin: bottom;

      @include transition (all 0.5s ease 0s);
    }

    &:hover {
      @include responsive-up(tablet) {
        i {
          animation-name: bouncearrow;
          animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
        }
      }
    }
  }

  a {
    color: white;

    &:hover {
      @include responsive-up(tablet) {
        text-decoration: none;
      }
    }
  }

  &-primary {
    display: flex;
    justify-content: space-between;

    @include responsive-down (mobile-extra-large) {
      display: flex;
      flex-wrap: wrap;
    }

    &-intro {
      margin: 0 60px 0 0;
      min-width: 150px;

      @include responsive-down (tablet) {
        margin: 0 100px 0 0;
      }

      @include responsive-down (mobile-extra-large) {
        padding: 0 0 24px 0;
        width: 50%;
      }

      &__logo {
        img {
          width: 120px;

          @include responsive-down(mobile-extra-large) {
            width: 150px;
          }
        }
      }

      &__adress {
        p {
          font-size: 12px;
          margin: 0;
          color: $white;

          @include responsive-down (mobile-extra-large) {
            font-size: 15px;
            line-height: 20px;
          }
        }

        a {
          font-size: 12px;

          @include transition (all 0.2s ease-in);

          &:hover {
            @include responsive-up(tablet) {
              color: $secondary-grey;
              cursor: pointer;
            }
          }

          @include responsive-down (mobile-extra-large) {
            font-size: 15px;
            font-weight: 700;
          }
        }

        .content {
          margin: 15px 0;
        }
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include responsive-down (tablet) {
        display: inline;
      }
    }

    &__item {
      min-width: 130px;
      position: relative;

      @include responsive-down (tablet-extra-large) {
        min-width: 120px;
      }

      @include responsive-down(tablet) {
        padding: 15px 0;
        border-top: 1px solid white;
      }

      &:last-child {
        @include responsive-down(mobile-extra-large) {
          border-bottom: 1px solid white;
        }
      }

      &-header {
        margin-bottom: 15px;
        position: relative;

        @include responsive-down (tablet) {
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .title {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 1px solid $secondary-light03;
          padding-bottom: 15px;

          @include responsive-down (tablet-large) {
            font-size: 12px;
          }

          @include responsive-down (tablet) {
            border-bottom: none;
            font-size: 14px;
            letter-spacing: 1px;
            padding: 7px 7px 7px 0;
          }
        }

        .icon {
          display: none;

          @include responsive-down(tablet) {
            display: block;
          }

          &-input {
            @include responsive-down (tablet) {
              width: 15px;
              height: 15px;
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 5;
              opacity: 0;
            }
          }

          &-cross {
            @include responsive-down (tablet) {
              width: 15px;
              height: 15px;
              position: absolute;
              right: 0;
              top: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              animation: plus-animation-reverse 0.5s ease-out forwards;
            }

            &::before,
            &::after {
              @include responsive-down (tablet) {
                content: "";
                display: block;
                position: absolute;
                border-radius: 4px;
                background: $white;
              }
            }

            &::before {
              @include responsive-down (tablet) {
                width: 1px;
                height: 11px;
              }
            }

            &::after {
              @include responsive-down (tablet) {
                width: 11px;
                height: 1px;
              }
            }
          }
        }
      }

      &-content {
        flex-direction: column;
        display: flex;

        @include responsive-down (tablet) {
          display: none;
          padding-top: 1em;
        }

        a {
          font-size: 12px;
          margin: 5px 0;

          @include transition (all 0.2s ease-in);

          &:hover {
            @include responsive-up(tablet) {
              color: $secondary-grey;
              text-decoration: none;
            }
          }

          @include responsive-down (tablet-large) {
            font-size: 11px;
          }
        }
      }

      &--opened {
        .footer-primary__item-content {
          @include responsive-down (tablet) {
            display: flex;
          }
        }

        .icon-cross {
          animation: plus-animation 0.5s ease-out forwards;
        }
      }
    }
  }

  &-secondary {
    margin: 50px 0 6px;
    border-top: 1px solid $white;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 50px;

    @include responsive-down (tablet) {
      margin: 10px 0 6px;
    }

    @include responsive-down (mobile-extra-large) {
      border-top: 0;
      display: block;
      margin: 15px 0 6px;
      padding-bottom: 80px;
    }

    &--terms {
      display: flex;
      align-items: baseline;

      @include responsive-down (tablet) {
        display: inline;
      }

      @include responsive-down (mobile-extra-large) {
        flex-direction: column-reverse;
        display: flex;
      }

      &__infor {
        margin: 0 10px 0 0;

        @include responsive-down (mobile-extra-large) {
          width: 100%;
          margin: 14px 0 0;
        }

        p {
          display: inline-block;
          font-size: 10px;
          color: white;
          opacity: 0.5;
          margin: 8px 0 0;

          @include responsive-down(mobile-extra-large) {
            font-size: 12px;
            opacity: 1;
            margin: 10px 0 0;
          }
        }
      }

      &__politics {
        a {
          font-size: 10px;
          padding: 0 10px;
          position: relative;
          color: white;
          opacity: 0.5;

          @include transition (all 0.2s ease-in);

          &:hover {
            @include responsive-up(tablet) {
              color: $secondary-grey;
            }
          }

          @include responsive-down(mobile-extra-large) {
            font-size: 12px;
            min-width: 50%;
            padding: 10px 0;
          }

          &::after {
            content: "";
            top: 2px;
            left: 0;
            width: 1px;
            height: 8px;
            background-color: white;
            position: absolute;

            @include responsive-down(mobile-extra-large) {
              display: none;
            }
          }

          &:first-child {
            @include responsive-down(tablet) {
              padding-left: 0;

              &::after {
                display: none;
                width: 0;
              }
            }
          }
        }

        @include responsive-down(mobile-extra-large) {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    &--redes {
      @media (max-width: 1680px) {
        margin-right: 40px;
      }

      @include responsive-down(desktop-extra-small) {
        margin-right: 0;
      }

      a {
        font-size: 10px;
        opacity: 1;
        margin: 0 0 0 15px;

        &:hover {
          @include responsive-up(tablet) {
            text-decoration: underline;
          }
        }
      }

      @include responsive-down(mobile-extra-large) {
        display: flex;
        flex-direction: column;

        a {
          font-size: 14px;
          text-decoration: underline;
          text-align: right;
          width: 100%;
          margin: 0 0 20px 0;
        }
      }
    }
  }
}

.contactfixed {
  position: fixed;
  width: auto;
  height: auto;
  bottom: 0;
  right: 0;
  z-index: 9;

  &::before {
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: $black04;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    @include transition(all 0.4s ease-in);
  }

  &-content {
    width: auto;
    position: absolute;
    bottom: 2vh;
    right: 2vh;
    z-index: 3;

    @include responsive-down(desktop-extra-small) {
      right: 15px;
    }

    @include responsive-down(tablet) {
      display: none;
      bottom: 80px;
    }

    &__btn {
      position: relative;
      background-color: $primary-red;
      border-radius: 10px;
      border-bottom-right-radius: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 55px;
      height: 55px;
      border: 0;
      text-decoration: none;
      z-index: 2;
      margin-top: 10px;
      padding: 0 12px;

      @include transition (all 0.5s ease-in-out);

      &:hover {
        @include responsive-up(tablet) {
          text-decoration: none;
          background-color: $hover-primary-red;

          @include transition (all 0.2s ease-in);
        }
      }

      @include responsive-down (tablet) {
        width: 205px;
        justify-content: flex-start;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        padding-bottom: 10px;
        display: block;
      }
    }

    &__icon {
      font-size: 30px;
      color: white;

      @include responsive-down(tablet) {
        margin: 0;
        font-size: 23px;
      }
    }

    &__text {
      position: absolute;
      top: 10px;
      width: 0;
      margin-left: 10px;
      opacity: 0;
      visibility: hidden;
      color: white;

      $mas_transition: opacity 1s ease-in-out,  width 1s ease-in-out 1s;

      @include transition ( $mas_transition );

      @include responsive-down(tablet) {
        visibility: visible;
        width: 80px;
        opacity: 1;
        line-height: 15px;
        top: 13px;
      }
    }

    &__date {
      position: absolute;
      width: 0;
      opacity: 0;
      visibility: hidden;
      color: $white;
      font-size: 12px;
      margin-left: 10px;
      top: 10px;
      padding-top: 20px;

      $mas_transition: opacity 1s ease-in-out,  width 1s ease-in-out 1s;

      @include transition ( $mas_transition );

      @include responsive-down(tablet) {
        visibility: visible;
        width: 100%;
        opacity: 1;
        line-height: 15px;
        top: 13px;
      }
    }

    &:last-child {
      &:hover {
        @include responsive-up(tablet) {
          .contactfixed-info {
            &__text {
              width: 100px;
            }
          }
        }
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        .contactfixed-content__btn {
          width: 205px;
          transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
        }

        .contactfixed-content__text {
          width: 80px;
          opacity: 1;
          visibility: visible;

          @include transition ( all 0.5s ease-in-out 0.4s);
        }

        .contactfixed-content__date {
          width: 100%;
          opacity: 1;
          visibility: visible;

          @include transition ( all 0.5s ease-in-out 0.4s);
        }
      }
    }
  }

  &--opened {
    width: 100%;
    height: 100vh;

    &::before {
      content: "";
      opacity: 1;
      z-index: 2;

      @include transition (all 0.5s ease-in-out);
    }

    .contactfixed-content {
      @include responsive-down(desktop-extra-small) {
        display: block;
        transform: translateX(0);

        @include transition (all 0.5s ease-in);
      }
    }
  }
}

.showmore {
  display: none;

  &-btn {
    cursor: pointer;

    @include responsive-down (desktop-extra-small) {
      width: 55px !important;
      height: 55px !important;
      justify-content: center !important;

      i {
        animation: plus-animation-reverse 0.5s ease-out forwards;
      }
    }

    @include responsive-down (mobile-large) {
      width: 42px !important;
      height: 42px !important;
    }

    &:focus {
      background-color: #ed6e6c;
    }

    i {
      @include responsive-down (mobile-large) {
        font-size: 18px;
      }
    }
  }

  @include responsive-down(tablet) {
    display: flex;
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 9;
  }

  &--opened {
    .showmore-btn {
      i {
        animation: plus-animation 0.5s ease-out forwards;
      }
    }
  }
}

.single-mas_cpt_training,
.single-mas_cpt_specialties {
  .showmore {
    @include responsive-down(tablet) {
      bottom: 100px;
    }
  }

  .contactfixed-content {
    @include responsive-down(tablet) {
      bottom: 175px;
    }
  }
}

@keyframes bouncearrow {
  0% { transform: scale(1, 1)      translateY(0); }
  10% { transform: scale(1.1, 0.9)   translateY(0); }
  30% { transform: scale(0.9, 1.1)   translateY(-25px); }
  50% { transform: scale(1.05, 0.95) translateY(0); }
  57% { transform: scale(1, 1)      translateY(-7px); }
  64% { transform: scale(1, 1)      translateY(0); }
  100% { transform: scale(1, 1)      translateY(0); }
}
