/* Block: Access area
--------------------------------------------------------------------------------------------------------------------- */
.access {
  padding: 35px 0 15px;
  width: 300px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  box-shadow: $box-shadow-generic;

  @include responsive-down(tablet) {
    width: 100%;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    color: $primary;
    font-weight: 500;
    max-width: 170px;
    margin: auto;

    @include responsive-down(tablet) {
      font-size: 20px;
      line-height: 24px;
      max-width: 65%;
    }

    @include responsive-down(mobile) {
      max-width: 100%;
      font-size: 18px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 45px 0 0;
    max-width: 230px;
    margin: auto;

    @include responsive-down(tablet) {
      max-width: 100%;
    }

    @include responsive-down(mobile) {
      padding: 30px 0 0;
    }
  }

  &__item {
    width: calc(100% / 2 - 20px);
    display: inline-block;
    text-align: center;
    padding: 0 0 20px;

    @include responsive-down(tablet) {
      width: calc(100% / 4);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include responsive-down(mobile) {
      width: calc(100% / 2 - 40px);
      margin: 0 20px;
    }

    @include responsive-down(mobile-extra-small) {
      width: calc(100% / 2 - 22.5px);
      margin: 0 7.5px 0 15px;
    }

    &:nth-of-type(2n) {
      @include responsive-down(mobile-extra-small) {
        margin: 0 15px 0 7.5px;
      }
    }

    &:hover {
      @include responsive-up(tablet-large) {
        text-decoration: none;

        .access__icon {
          transform: translateY(-10px);
          transition: all 0.3s ease-out;
          background-color: $primary;

          i {
            color: white;
          }
        }
      }
    }
  }

  &__icon {
    background-color: $services-blue-light03;
    border-radius: 15px;
    width: 70px;
    height: 70px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    transform: translateY(0);
    transition: all 0.5s ease-out;
    padding: 15px;

    @include responsive-down(tablet) {
      margin-bottom: 10px;
    }

    @include responsive-down(mobile) {
      width: 100%;
      border-radius: 5px;
    }

    i {
      font-size: 22px;
      color: $primary-blue;
      text-align: center;
    }
  }

  &__name {
    color: $primary;
    font-size: 10px;
    margin: 0;
    font-weight: 500;
    display: inline-block;

    @include responsive-down(mobile) {
      font-size: 12px;
    }
  }
}
