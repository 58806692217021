.areafooter {
  padding: 64px 30px 20px;

  @include responsive-down(tablet) {
    padding: 30px 15px 20px;
  }

  .go-top {
    bottom: 45px;

    @include responsive-down(tablet) {
      bottom: 15px;
    }
  }

  &__corporate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include responsive-down(tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__contact {
    @include responsive-down(tablet) {
      order: 3;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

    @include responsive-down(mobile) {
      order: 2;
    }

    a {
      font-size: 14px;
      margin: 8px;
      display: inline-block;
      position: relative;

      @include transition (all 0.25s ease-in);

      @include responsive-down(tablet) {
        font-size: 16px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        @include responsive-down(tablet) {
          margin-right: 0;
          padding-right: 0;
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $white;
        opacity: 0.75;
        transform: scale(0);

        @include transition (all 0.4s ease-in);

        @include responsive-down(tablet-large) {
          display: none;
        }
      }

      &:hover {
        @include responsive-up(tablet-large) {
          &::before {
            transform: scale(1);

            @include transition (all 0.4s ease-in);
          }
        }
      }
    }
  }

  &__logo {
    width: 300px;

    @include responsive-down(tablet) {
      order: 1;
      width: 50%;
    }

    &--desktop {
      display: block;

      @include responsive-down(tablet) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      width: 70%;
      margin: 0 auto;

      @include responsive-down(tablet) {
        display: block;
      }
    }
  }

  &__social {
    @include responsive-down(tablet) {
      order: 2;
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    @include responsive-down(mobile) {
      order: 3;
      width: 100%;
    }

    .footer-secondary--redes {
      @media (max-width: 1680px) {
        margin-right: 0;
      }

      @include responsive-down(tablet) {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        flex-direction: row;
        flex-direction: column;
      }

      @include responsive-down(mobile) {
        align-items: center;
        flex-direction: row;
      }

      a {
        font-size: 14px;

        @include responsive-down(tablet) {
          text-align: left;
          width: auto;
          margin-right: 10px;
          margin-bottom: 5px;
          text-decoration: underline;
        }

        @include responsive-down(mobile) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__legal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid $white;

    @include responsive-down(tablet) {
      margin-top: 0;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #ffffff85;
    }

    p {
      margin: 0;
      color: $white;
      opacity: 0.75;
      font-size: 12px;

      @include responsive-down(mobile) {
        margin-bottom: 10px;
        font-size: 11px;
      }
    }

    a {
      font-size: 12px;
      display: inline-block;
      margin-right: 5px;
      opacity: 0.75;

      @include responsive-down(mobile) {
        font-size: 9px;
      }

      &:hover {
        @include responsive-up(tablet-large) {
          text-decoration: underline;

          @include transition (all 0.25s ease-in);
        }
      }
    }
  }
}
