/* Layouts: SINGLE(POST)
--------------------------------------------------------------------------------------------------------------------- */
.blogpost {
  &-header {
    .blog-nav {
      width: 100%;
      right: 0;
      margin: 0 auto;
      justify-content: center;
    }
  }

  &-intro {
    padding: 90px 0 0;
    display: flex;
    flex-direction: column;
    text-align: center;

    &__title {
      font-size: 33px;
      line-height: 1.3;
      max-width: 660px;
      text-align: center;
      color: $primary;
      margin: 0 auto 20px auto;
      text-transform: initial;

      @include responsive-down (tablet) {
        font-size: 28px;
      }

      @include responsive-down (mobile-extra-large) {
        font-size: 26px;
        line-height: 30px;
        max-width: 460px;
      }

      @include responsive-down (mobile-small) {
        text-align: left;
        font-size: 22px;
      }

      @include responsive-down (mobile-extra-small) {
        text-align: left;
        font-size: 20px;
        line-height: 25px;
        margin: 0 auto 10px 0;
      }
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      padding: 0 0 25px;
      margin: 0 auto;
      letter-spacing: 0.5px;
      color: $grey;

      @include responsive-down (mobile-small) {
        text-align: left;
        margin: 0;
        padding: 0 0 30px;
      }
    }

    &__metadate {
      padding-left: 25px;
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 450px;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      box-shadow: $box-shadow-generic;

      @include responsive-down(mobile-extra-large) {
        max-height: 400px;
      }

      @include responsive-down(mobile) {
        max-height: 300px;
      }
    }

    &__share {
      width: 100%;
      margin: 0 10px 0 0;
      text-align: left;
      padding: 15px 0 0;
      display: flex;
      align-items: center;
    }

    &__sharelabel {
      color: $primary;
      text-align: left;
      font-size: 16px;
      font-weight: 500;

      @include responsive-down(mobile-extra-small) {
        font-size: 14px;
      }
    }

    &__sharerrss {
      padding: 0 0 0 20px;
      display: inline-flex;
      align-items: center;

      i {
        font-size: 20px;
        color: $primary-blue;

        @include transition (all 0.2s ease-in);

        &:hover {
          @include responsive-up(tablet) {
            color: $primary;
          }
        }
      }

      a {
        text-decoration: none;
        display: block;
        margin-right: 7px;

        &:first-child {
          margin-right: 12px;
        }
      }

      .link--copied {
        color: $primary-blue;
        font-size: 12px;
        align-items: center;
        margin: 0 0 0 10px;
        display: none;

        i {
          margin-right: 5px;
          font-size: 14px;
        }
      }
    }
  }

  &-content {
    padding: 30px 60px 0;

    @include responsive-down(tablet-small) {
      padding: 0;
    }

    h2,
    h3,
    h4,
    h5 {
      margin: 50px 0 5px;
      font-weight: 700;
      color: $primary;
      line-height: 1.5;

      @include responsive-down(mobile) {
        line-height: 1.4;
      }
    }

    h2 {
      font-size: 28px;

      @include responsive-down(mobile-large) {
        font-size: 26px;
      }
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    ul {
      padding-left: 18px;

      li {
        list-style: disc;
        margin-bottom: 0;
        line-height: 24px;
      }
    }

    ol {
      padding-left: 18px;

      li {
        margin-bottom: 0;
        line-height: 24px;
      }
    }

    p,
    a,
    ul li,
    ol li {
      font-size: 18px;
      line-height: 1.8;

      @include responsive-down(mobile) {
        font-size: 16px;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 14px;
      }
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      border-radius: 10px;
      border-bottom-left-radius: 0;
    }
  }

  &-footer {
    &__nav {
      display: flex;
      justify-content: space-between;
      padding: 10px 0 20px;
      margin: 50px 0 0;
      border-top: 1px solid $secondary-grey;

      @include responsive-down(tablet) {
        margin: 20px 0 0;
      }

      a {
        text-decoration: none;
      }
    }

    &__navlabel {
      font-size: 12px;
      color: $primary-blue;
      display: inline-flex;
      align-items: center;

      @include transition (all 0.2s ease-in);

      &:hover {
        @include responsive-up(tablet) {
          color: $primary-headertop;
        }
      }

      i {
        font-size: 10px;
        margin: 0 5px;
        font-weight: 700;
      }
    }

    &__navtitle {
      font-size: 14px;
      font-weight: 500;
      margin: 0;

      @include responsive-down(mobile-small) {
        font-size: 12px;
        line-height: 1.6;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 11px;
      }
    }

    &__navprev {
      width: 30%;

      @include responsive-down(mobile-large) {
        width: 40%;
      }

      @include responsive-down(mobile-extra-small) {
        width: 45%;
      }
    }

    &__navnext {
      text-align: right;
      width: 30%;

      @include responsive-down(mobile-large) {
        width: 40%;
      }

      @include responsive-down(mobile-extra-small) {
        width: 45%;
      }
    }

    &__searcher {
      padding: 0;
    }

    &__searcherform {
      position: relative;

      .btn {
        position: absolute;
        right: 0;
        top: 8px;
        padding: 18px 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

/* IMG WORDPRESS
--------------------------------------------------------------------------------------------------------------------- */
.wp-caption {
  &-text,
  figcaption {
    color: $primary;
    font-size: 10px;
    font-weight: 700;
    display: inherit;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 5px 0;
  }
}

/* SECTIONS-INTERFAZ AUDIO
--------------------------------------------------------------------------------------------------------------------- */
.mejs-controls {
  background: $primary !important;
  border-radius: 50px !important;
}

.mejs-container {
  background: transparent;
}

/* SECTION GALLERYS
--------------------------------------------------------------------------------------------------------------------- */
.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  &-item {
    margin: 20px;
    width: calc(100% / 3 - 40px);

    @include responsive-down(mobile-large) {
      width: calc(100% / 3 - 30px);
      margin: 15px;
    }

    @include responsive-down(mobile) {
      width: calc(100% / 2 - 30px);
      margin: 20px;

      &:nth-child(2n + 1) {
        margin: 10px 10px 10px 20px;
      }

      &:nth-child(2n) {
        margin: 10px 20px 10px 10px;
      }
    }

    img {
      width: 100% !important;
      object-fit: cover !important;
      height: auto;
    }
  }
}

/* Table of contents
--------------------------------------------------------------------------------------------------------------------- */
div#ez-toc-container {
  margin: 50px 0;
  padding: 30px;
  background: #f7f6f3;
  border: none;
  border-radius: 20px;

  @include responsive-down(tablet) {
    margin: 30px 0;
    padding: 30px 20px;
  }

  .ez-toc-title-container {
    margin-bottom: 20px;

    .ez-toc-title {
      color: $primary;
    }
  }
}

/* VIDEO
--------------------------------------------------------------------------------------------------------------------- */
.wp-video {
  padding: 20px 0;
}

.wp-video-shortcode {
  border-radius: 20px;
  border-bottom-left-radius: 0;

  .mejs-controls {
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

figure {
  margin: 0;
  width: auto;
  height: auto;
  max-width: 100%;
}

blockquote {
  @include responsive-down(mobile-small) {
    margin: 20px 10px 20px 30px;
  }

  @include responsive-down(mobile-extra-small) {
    margin: 20px 10px 20px 20px;
  }
}
