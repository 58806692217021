/* Layouts: Search curses
--------------------------------------------------------------------------------------------------------------------- */

.search {
  padding: 40px 0 40px 0;

  @include responsive-down(mobile-small) {
    padding: 20px 0 0;
  }

  &__intro {
    padding: 30px 0 40px 0;

    @include responsive-down(mobile-small) {
      padding: 24px 0 0;
    }

    .container {
      max-width: 1450px;
    }

    &--title {
      font-size: 30px;
      text-align: center;
      color: $primary;
      font-weight: 500;

      @include responsive-down(mobile-small) {
        font-size: 22px;
      }
    }

    &--subtitle {
      text-align: center;
      color: $primary;
      margin: 12px 0;
      padding: 0 220px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      @include responsive-down(tablet) {
        padding: 0 20px;
      }

      @include responsive-down(mobile-small) {
        padding: 0;
      }
    }
  }

  &-rowdate {
    display: inline-flex;
    gap: 10px;

    @include responsive-down(tablet-small) {
      width: 100%;
      margin: 0 0 5px;
    }

    @include responsive-down(mobile-small) {
      justify-content: space-between;
    }

    &__calendar {
      border: 0;
      background-color: $secondary-light03;
      width: 100%;
      margin: 0;
      color: $primary;
      font-size: 14px;
      padding: 16px;
      cursor: pointer;

      @include responsive-down(mobile-small) {
        width: calc(100% / 2 - 6px) !important;
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &-rowcurse {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 12px 0 0 0;
    gap: 10px;

    @include responsive-down(tablet-small) {
      display: block;
      margin: 4px 0 0 0;
    }

    &__group {
      width: 100%;
    }

    &__location {
      margin-right: 0 !important;
    }

    &__select {
      width: 50%;
      margin-right: 0 !important;
    }

    span {
      width: 100% !important;
    }

    .search__row-loading-item {
      margin-right: 0;
    }
  }

  &-rowavanced {
    position: relative;
    cursor: pointer;
    touch-action: manipulation;

    &--title {
      font-size: 17px;
      font-weight: 700;
      color: $primary;
      margin: 18px 0;

      @include responsive-down(mobile-small) {
        font-size: 14px;
        margin: 30px 0 14px;
      }
    }

    &::after {
      content: "\e90b";
      font-family: "icomoon", sans-serif;
      position: absolute;
      top: 2px;
      left: 200px;
      font-size: 14px;
      font-weight: 700;
      color: $primary-blue;

      @include responsive-down(mobile-small) {
        top: 3px;
        left: 160px;
      }
    }

    &--open {
      &.search__rowavanced--open {
        &::after {
          content: "\e90a";
          font-family: "icomoon", sans-serif;
        }
      }
    }
  }

  &-rowdropdown {
    // display: none;

    &-group {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      z-index: -1;
    }
  }

  &__rowoptionsavanced {
    display: flex;
    flex-wrap: wrap;

    @include responsive-down(mobile-large) {
      display: block;
    }

    &-item {
      position: relative;
      width: calc(100% / 3 - 13.33333px);
      margin-right: 20px;
      height: 48px;

      @include responsive-down(tablet-small) {
        margin: 4px 0 10px 0;
        width: 100%;
      }

      .span-optionsavanced {
        position: absolute;
        font-size: 9px;
        color: $primary;
        top: 10px;
        left: 17px;
      }

      span {
        width: 100% !important;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      &.select-false-container {
        margin: 0;
      }

      .select2-container,
      &.select-false-container .search__rowoptionsavanced--select {
        &::before {
          content: "\e902";
          font-family: 'icomoon', sans-serif !important;
          color: $primary;
          position: absolute;
          top: 16px;
          right: 12px;
          font-weight: 700;
          font-size: 14px;
          z-index: -1;
        }
      }

      .select2-container--open,
      &.false-select--opened .search__rowoptionsavanced--select {
        &::before {
          transform: rotate(180deg);
        }
      }
    }

    &--select {
      background-color: $secondary-light03;
      width: 100%;
      padding: 24px 17px 10px;
      font-size: 12px;
      color: $black;
      position: relative;

      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }

  &-rowShowCurses {
    display: inline-flex;
    padding: 15px 0;

    @include responsive-down(tablet) {
      display: initial;
    }

    &__item {
      padding: 0 30px 0 0;

      &:first-child {
        @include responsive-down(tablet) {
          margin-top: 30px;
        }
      }
    }

    label {
      font-size: 12px;
    }
  }

  &-rowdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 20px;

    @include responsive-down(mobile) {
      display: block;
    }

    &--left {
      width: auto;
      position: relative;
      padding: 15px;
      background-color: $services-blue-light03;
      display: inline-flex;
      align-items: center;

      @include responsive-down(tablet-extra-small) {
        width: 50%;
      }

      @include responsive-down(mobile) {
        width: 100%;
        margin: 0 0 15px;
      }

      i {
        color: $primary;

        @include responsive-down(mobile) {
          top: 0;
        }
      }

      &__text {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 0 10px;
      }

      &__link {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 0 2px;
      }
    }

    &--login {
      background-color: $white;
      padding: 0;
      margin: 0;
    }

    &--right {
      @include responsive-down(mobile) {
        .btn {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }

  &__result {
    padding: 10px 0 30px;

    &--title {
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      color: $primary;
      border-bottom: 1px solid $primary;
      padding: 14px 0;
      margin: 0;

      @include responsive-down(tablet) {
        font-size: 16px;
      }

      @include responsive-down(mobile-large) {
        font-size: 14px;
      }
    }

    &--table {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;

      .table-container {
        display: flex;
        flex-flow: column nowrap;
        background-color: white;
        width: 100%;

        @include responsive-down (tablet-small) {
          flex-flow: row wrap;
        }
      }

      &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid #dadada;
        position: relative;

        @include responsive-down(tablet-small) {
          display: flex;
          width: 50%;
          flex-direction: column;
          padding: 20px;
          justify-content: space-between;
          align-items: flex-start;

          &:first-child {
            display: none;
          }
        }

        @include responsive-down(mobile-large) {
          width: 100%;
          padding: 13px 0;
        }

        &:first-child {
          border: 0;
        }

        &--item {
          display: flex;
          width: 13%;
          font-size: 11px;
          line-height: 14px;
          padding: 30px 20px;
          justify-content: left;
          align-items: center;
          transition: all 0.15s ease-in-out;

          @include responsive-down(tablet-small) {
            padding: 5px 0 6px 0;
            font-size: 14px;
            flex: inherit;
            width: 100%;
          }

          &:first-child {
            width: 15%;
            padding: 30px 20px 40px 10px;

            @include responsive-down(tablet-small) {
              width: 100%;
              padding: 20px 0;
            }
          }

          &:nth-child(3),
          &:nth-child(4) {
            width: 12.5%;

            @include responsive-down(tablet-small) {
              width: 100%;
            }
          }

          &::before {
            content: attr(data-label);
            text-align: left;
            font-size: 14px;
            display: none;
            color: $primary;
            min-width: 100px;

            @include responsive-down(tablet-small) {
              display: inline-flex;
            }
          }
        }

        .private {
          display: inline-flex;
          align-items: flex-start;

          i {
            font-size: 11px;
            color: $primary;
            margin: 3px 0 0;
          }

          &__body {
            display: inline-flex;
            flex-direction: column;
            padding: 0 0 0 10px;
          }

          &__heading {
            font-weight: 600;
            color: $primary;

            @include responsive-down(tablet-small) {
              margin: 0 0 6px;
            }
          }
        }

        &--category {
          @include responsive-down(tablet-small) {
            font-size: 16px !important;
            line-height: 18px;
            color: $primary;
            font-weight: 700;
            padding: 10px 0 15px;

            &::before {
              display: none;
            }
          }
        }

        &--heading {
          font-size: 18px;
          font-weight: 700;
          color: $primary;
          padding: 30px 20px 5px !important;

          @include responsive-down (tablet) {
            font-size: 16px;
          }

          @include responsive-down (tablet-small) {
            display: none;
          }

          @include responsive-down(mobile-small) {
            font-size: 14px;
          }
        }

        &--inscription {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 0;
          width: 21%;

          @include responsive-down(tablet-small) {
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            width: 100%;
          }

          &::before {
            display: none;
          }

          .curse-btn {
            margin: 10px 0;
            display: inline-block;

            @include responsive-down(tablet-small) {
              font-size: 10px;
              color: $primary;
            }
          }

          .btn {
            position: relative;
            justify-content: center;
            padding: 15px 12px;

            @include responsive-down(tablet-small) {
              width: 100%;
            }

            &.btn--info {
              position: absolute;
              left: 0;
              bottom: 0;

              @include responsive-down(tablet-small) {
                position: relative;
              }
            }

            i {
              &::before {
                content: "\e905";
              }
            }
          }

          .btn-div {
            display: flex;
            width: 100%;
            gap: 10px;
            justify-content: flex-end;

            @include responsive-down(desktop-small) {
              flex-direction: column;
              justify-content: center;
            }

            @include responsive-down(tablet-small) {
              width: calc(100% + 10px);
              margin: 10px 0 0;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
            }

            @include responsive-down(mobile-extra-large) {
              margin: 20px 0 0;
              flex-direction: column;
            }
          }
        }
      }
    }

    &-showregistered {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: wrap;
      margin-top: 30px;

      @include responsive-down(tablet) {
        margin-top: 10px;
      }

      &--text {
        font-size: 14px;
        color: $primary-blue;
        line-height: 15px;

        @include responsive-down(mobile-small) {
          font-size: 11px;
          display: inline;
          width: 100%;
        }
      }

      &--link {
        font-size: 12px;
        background-color: transparent;
        color: $primary-blue;
        padding: 0;

        @include responsive-down(mobile-small) {
          display: inline;
          color: $primary;
          text-align: left;
          text-decoration: underline;
          width: 100%;
          font-size: 11px;
          letter-spacing: 2px;
        }

        &:hover {
          @include responsive-up(tablet) {
            background-color: transparent;
            color: $primary;
          }
        }

        &:focus {
          background-color: transparent;
          color: $primary-blue;
        }
      }

      &-loading {
        .loading {
          width: 150px;
          min-height: 20px;
        }
      }
    }

    &--error {
      display: flex;
      align-items: baseline;
      position: relative;
      margin-top: 40px;

      i {
        font-size: 20px;
        color: $warning;
        position: absolute;
      }

      .search__text {
        font-size: 14px;
        color: $warning;
        margin: 0 0 0 30px;
      }

      .search__action {
        font-size: 10px;
        letter-spacing: 1px;
        border: 0;
        color: $primary-blue;
        background-color: transparent;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: "icomoon", sans-serif;

        &:hover {
          @include responsive-up(tablet) {
            color: $primary;
          }
        }

        &:focus {
          @include responsive-up(tablet) {
            color: $black;
          }
        }
      }
    }
  }

  &__share {
    width: 100%;
    padding: 30px 0;
    position: relative;
    margin: 0 auto;

    &-title {
      color: $primary;
      font-size: 14px;
      margin: 6px 0;
    }

    &-link {
      background-color: $secondary-light03;
      display: inline-flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      border-radius: 5px;

      &--p {
        color: $primary;
        margin: 16px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @include responsive-down (mobile-small) {
          margin: 12px;
        }
      }

      &--icon {
        font-size: 24px;
        color: white;
        background-color: $primary-blue;
        padding: 15px 14px;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:hover {
          @include responsive-up(tablet) {
            background-color: $primary;
            text-decoration: none;
          }
        }

        @include responsive-up(mobile-small) {
          padding: 12px;
        }
      }
    }

    &--copied {
      position: relative;

      &-a {
        position: absolute;
        color: $primary-blue;
        margin: 0;
        font-size: 12px;
        align-items: center;
        bottom: 0;
        right: 0;

        i {
          margin-right: 5px;
          font-size: 14px;
        }
      }
    }
  }

  .btn {
    display: inline-flex;
    align-items: center;
    text-align: center;

    @include responsive-down(tablet-small) {
      display: inline;
    }

    i {
      display: inline-flex;
      margin-left: 5px;
      font-size: 7px;
    }
  }

  .loading {
    position: relative;
    min-height: 60px;
    margin: 20px 0 0;
    border: 0;
    width: 100%;
    background-color: #f3f2ee;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: -45%;
      height: 100%;
      width: 45%;
      background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
      animation: loading 1s infinite;
    }
  }

  .modal-search {
    display: none;
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.1);

    &__content {
      box-shadow: $box-shadow-generic;
      padding: 75px 100px;
      background-color: $white;
      border-radius: 15px;
      border-bottom-left-radius: 0;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 600px;
      height: 400px;
      margin: auto;
      z-index: 1;
      bottom: 0;
      top: 0;

      @include responsive-down(tablet) {
        padding: 75px;
      }

      @include responsive-down(mobile) {
        padding: 75px 25px 50px;
        width: 95%;
        height: auto;
      }
    }

    &::before {
      content: "";
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(34, 34, 34, 0.2);
    }

    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      i {
        display: inline-block;
        font-size: 22px;
        color: $primary-blue;
        transform: rotate(45deg);
      }
    }

    &__header {
      font-size: 18px;
      font-weight: 600;
      color: $primary;
      margin-bottom: 20px;

      i {
        color: $primary-blue;
        display: inline-block;
        margin-right: 5px;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }

    &__title {
      display: inline-block;
      margin-bottom: 7.5px;
      color: $primary;
    }

    &--active {
      display: flex;
    }
  }
}

.select2 {
  &-selection {
    &__rendered {
      padding: 17px 17px 10px !important;
      color: $black !important;
      font-size: 12px;
    }
  }

  &-container--default .select2-selection--single {
    border: 0;
    background-color: $secondary-light03;
    height: 48px;

    .select2-selection__arrow {
      display: none;
      appearance: none;
    }
  }

  &-selection .select2-selection--single {
    padding: 10px;
  }

  .select2-container .select2-container--default {
    width: 100%;
  }

  &-dropdown {
    padding: 0;
    background-color: $secondary-light1;
    border: 0 solid;
    border-radius: 10px;

    .select2-search--dropdown {
      padding: 5px 17px;

      .select2-search__field {
        font-size: 12px;
        border: 1px solid $light-grey;
        background-color: white;
      }
    }

    .select2-results {
      ul li {
        font-size: 12px;
        color: $primary;
        padding: 5px 17px;
      }
    }
  }
}

.select2 .select-container .select2-container--default {
  width: 100% !important;
}

.select2-search.select2-search--dropdown {
  position: relative;

  &::after {
    content: "Buscar...";
    position: absolute;
    color: $primary;
    left: 20px;
    top: 0;
    font-size: 9px;
  }
}
