/* Breakpoints
/-------------------------------------------------------------------------------------------------------------------- */
$desktop-extra-large: 2560px;
$desktop-large: 1919px;
$desktop: 1600px;
$desktop-small: 1440px;
$desktop-extra-small: 1366px;
$tablet-extra-large: 1280px;
$tablet-large: 1080px;
$tablet: 991px;
$tablet-small: 860px;
$tablet-extra-small: 768px;
$mobile-extra-large: 720px;
$mobile-large: 640px;
$mobile: 540px;
$mobile-small: 480px;
$mobile-extra-small: 375px;

/* Typography
--------------------------------------------------------------------------------------------------------------------- */
$font-aeonik: 'aeonik', sans-serif;
$icomoon: 'icomoon', sans-serif;
$letter-spacing: 0.03rem;
$letter-spacing-2x: 0.06rem;
$letter-spacing-big: 0.1rem;

/* Color
/-------------------------------------------------------------------------------------------------------------------- */
$primary: #144689;
$primary-headertop: #0e3160;
$primary-blue: #36a9e1;
$primary-red: #ed6e6c;
$hover-primary-red: #913f3d;
$black: #222;
$black01: rgba(34, 34, 34, 0.1);
$black02: rgba(34, 34, 34, 0.2);
$black03: rgba(34, 34, 34, 0.3);
$black04: rgba(34, 34, 34, 0.4);
$grey: #777;
$white: #fff;
$light-grey: #faf9f8;
$secondary-blue: #1f67a6;
$secondary-blue-light: #2b88c4;
$secondary-dark: #0b2343;
$secondary-light: #e8e2da;
$secondary-light03: rgba(232, 226, 218, 0.3);
$secondary-dark-grey: rgba(232, 226, 218, 1);
$secondary-light1: #f7f6f3;
$secondary-grey: #c9c6c5;
$services-blue-dark: #59a8dc;
$services-blue: #71b4e0;
$services-blue-light: #90c5e8;
$services-blue-light03: rgba(56, 67, 73, 0.2);
$warning: #ff3202;
$success: #38d631;
$background: #f5f5f5;

//Animations
$easing: cubic-bezier(0.25, 1.7, 0.35, 0.8);
$duration: 0.75s;

//Box-shadow
$box-shadow-generic: 0 0 20px 5px rgba(34, 34, 34, 0.05);
