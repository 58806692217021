/* Layouts: Home
--------------------------------------------------------------------------------------------------------------------- */
.aboutus {
  &-intro {
    padding: 70px 0 30px;

    @include responsive-down(mobile-small) {
      padding: 50px 0;
    }

    &__title {
      font-size: 30px;
      text-align: center;
      color: $primary;
      margin: 0 0 10px;

      @include responsive-down(mobile-extra-large) {
        font-size: 28px;
      }

      @include responsive-down(mobile) {
        font-size: 26px;
      }

      @include responsive-down(mobile-small) {
        text-align: left;
      }
    }

    p {
      color: $primary;
      text-align: center;
      margin: 0;

      @include responsive-down(mobile-small) {
        text-align: left;
      }
    }
  }

  &-ourhistory {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      background-color: $primary-blue;
      height: 2px;
      top: 56px;
      left: 0;

      @include responsive-down(tablet) {
        top: 59px;
      }
    }

    &__intro {
      padding: 0 0 17px;

      @include responsive-down (mobile-large) {
        padding: 0 0 25px;
      }
    }

    &__introtitle {
      color: $primary;
      font-size: 26px;

      @include responsive-down (mobile-large) {
        font-size: 22px;
      }

      @include responsive-down (mobile-extra-small) {
        font-size: 20px;
      }
    }

    &__list {
      display: flex;
      padding: 40px 100px 80px;
      overflow-y: hidden;
      overflow-x: visible;
      position: relative;
      scrollbar-width: none;

      @include responsive-down(tablet-extra-large) {
        padding: 40px 50px 90px;
      }

      @include responsive-down(mobile-large) {
        padding: 40px 20px 90px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      min-width: 280px;
      box-shadow: $box-shadow-generic;
      padding: 24px 20px 40px;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      margin: 0 40px 0 0;
      position: relative;
      height: 200px;

      @include responsive-down(mobile-extra-large) {
        height: 100%;
        min-width: 250px;
        min-height: 195px;
      }

      @include responsive-down(mobile-large) {
        height: 100%;
        min-width: 250px;
        min-height: 185px;
      }

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        background-color: $primary;
        height: 25px;
        top: -25px;
      }

      &::after {
        content: '';
        position: absolute;
        left: auto;
        top: -34px;
        transform: translateX(-46%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $primary;
      }
    }

    &__datetitle {
      color: $primary;
      font-size: 18px;
      line-height: 23px;

      @include responsive-down(mobile-extra-large) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__datetext {
      font-size: 14px;
      line-height: 18px;
      margin: 7px 0 0;

      @include responsive-down(mobile-extra-large) {
        font-size: 13px;
      }

      &::after {
        content: "...";
      }
    }

    &-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #36a9e1;
      background-color: transparent;
      display: inline;
      width: 45px;
      height: 40px;
      padding: 0;

      i {
        font-size: 22px;
        position: absolute;
        right: 12px;
        bottom: 12px;
        color: $primary-blue;
        background-color: transparent;
        border-radius: 100%;
        padding: 3px 1px 3px 3px;

        @include transition(all 0.25s ease-in-out);
      }

      &:hover {
        @include responsive-up(tablet) {
          background-color: transparent;

          i {
            color: $white;
            background-color: $primary-blue;

            @include transition(all 0.25s ease-in-out);
          }
        }
      }

      &:focus {
        background-color: transparent;
      }
    }
  }

  &-modal {
    display: flex;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: all 0.5s ease-out;

    &::before {
      content: "";
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: $black02;
    }

    &__content {
      box-shadow: $box-shadow-generic;
      padding: 75px 50px 24px;
      background-color: white;
      border-radius: 15px;
      border-bottom-left-radius: 0;
      position: relative;
      display: flex;
      width: 800px;
      height: 400px;
      margin: auto;
      z-index: 1;
      bottom: 0;
      top: 0;
      transform: translateY(125%);
      transition: all 0.5s ease-out;

      @include responsive-down(tablet) {
        height: auto;
        min-height: 400px;
        padding: 60px 50px 24px 50px;
      }

      @include responsive-down(tablet-small) {
        width: 80%;
      }

      @include responsive-down(mobile-large) {
        display: block;
        padding: 0;
      }

      @include responsive-down(mobile) {
        font-size: 20px;
      }

      i {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 22px;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
      color: $primary;

      @include responsive-down(tablet-extra-small) {
        font-size: 18px;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 16px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 18px;
      margin: 20px 0 0;
    }

    &__info {
      @include responsive-down(mobile-large) {
        padding: 0 50px 25px;
      }

      @include responsive-down(mobile-small) {
        padding: 0 30px 30px;
      }
    }

    &__img {
      margin-right: 25px;
      width: 173px;
      height: 263px;
      object-fit: cover;

      @include responsive-down(mobile-large) {
        height: 220px;
        width: 100%;
        margin-bottom: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      @include responsive-down(mobile-small) {
        height: 180px;
      }
    }

    &__close {
      font-weight: 700;
      position: absolute;
      margin: 0;
      padding: 0;
      padding: 13px;
      top: 20px;
      right: 20px;
      z-index: 3;

      i {
        font-size: 22px;
        color: $primary-blue;
        background: $white;
        top: 0;
        right: 0;
        padding: 3px;
        border-radius: 100%;
        background-color: transparent;

        @include transform (rotate(45deg));
      }

      &:hover,
      &:focus {
        @include responsive-up(tablet) {
          color: $primary;
          text-decoration: none;
          cursor: pointer;

          i {
            color: $white;
            background-color: $primary-blue;

            @include transform(all 0.5s ease-in-out);
          }
        }
      }
    }

    &--active {
      opacity: 1;
      z-index: 999999;
      transition: all 0.25s ease-in;

      .aboutus-modal__content {
        transform: translateY(0);
        transition: all 0.5s ease-in;
      }
    }
  }

  &-mision {
    width: 50%;
    padding: 90px 55px 45px;
    overflow: hidden;
    position: relative;

    @include responsive-down (tablet-extra-small) {
      padding: 70px 55px 45px;
    }

    @include responsive-down (mobile-extra-large) {
      width: 100%;
      padding: 60px 40px 45px;
    }

    @include responsive-down (mobile) {
      padding: 50px 50px 45px 20px;
    }

    @include responsive-down (mobile-extra-small) {
      padding: 40px 20px;
    }

    &__title {
      font-size: 24px;
      text-align: left;
      color: $white;
      margin-bottom: 25px;
      display: inline-flex;
      align-items: center;

      @include responsive-down (mobile-large) {
        margin-bottom: 15px;
      }

      @include responsive-down (mobile-extra-small) {
        font-size: 18px;
      }

      i {
        margin: 0 10px 0 0;

        @include responsive-down (mobile-extra-large) {
          margin: 0 10px 0 0;
          font-size: 22px;
        }

        @include responsive-down (mobile) {
          margin: 0 15px 0 0;
          font-size: 25px;
        }
      }
    }

    p {
      text-align: left;
      color: $white;
      font-size: 16px;
      margin: 0;

      @include responsive-down (tablet) {
        font-size: 14px;
        line-height: 20px;
      }

      @include responsive-down (mobile-extra-large) {
        padding: 0 0 0 33px;
      }

      @include responsive-down (mobile) {
        padding: 0 0 0 40px;
      }

      @include responsive-down (mobile-extra-small) {
        padding: 0 0 0 40px;
        margin: 0;
      }
    }

    .js-fade {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background: $primary-blue;
        z-index: -1;
        transform: translateX(-125%);
        transition: all 1.2s ease;
      }

      &--active {
        &::before {
          transform: translateX(0);
          transition: all 1.2s ease;
        }
      }
    }

    &:nth-of-type(2) {
      .js-fade {
        &::before {
          background: $primary;
          transition-delay: 0.5s;
        }
      }
    }
  }

  &-misionVision {
    display: inline-flex;

    @include responsive-down (tablet) {
      margin: 0 -20px;
    }

    @include responsive-down (mobile-extra-large) {
      display: block;
    }
  }

  &-values {
    padding: 85px 55px;
    background-color: $secondary-light03;

    @include responsive-down (tablet) {
      margin: 0 -20px;
      padding: 85px 20px;
    }

    @include responsive-down (tablet-small) {
      padding: 50px 20px;
    }

    &__title {
      color: $primary;
      font-size: 26px;
      margin-bottom: 30px;

      @include responsive-down (tablet-extra-small) {
        font-size: 22px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 0 22px 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__item {
      width: calc(100% / 5 - 37px);

      @include responsive-down (tablet-large) {
        width: calc(100% / 5 - 20px);
      }

      @include responsive-down (tablet-small) {
        width: calc(100% / 3 - 35px);
        padding: 0 0 30px;

        &:nth-child(5) {
          margin: 0 auto 0 52px;
        }
      }

      @include responsive-down (mobile-large) {
        width: calc(100% / 2 - 20px);

        &:nth-child(5) {
          margin: 0 auto 0 0;
        }
      }

      @include responsive-down (mobile-extra-small) {
        width: calc(100% / 1 - 20px);
      }
    }

    &__itemtitle {
      color: $primary;
      font-size: 16px;
      margin: 10px 0 0;
      align-items: baseline;

      @include responsive-down (mobile-extra-small) {
        display: inline-block;
        margin: 10px 0 0 10px;
      }
    }

    &__itemimg {
      height: 30px;
    }

    &__itemcontent {
      p {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  &-acreditations {
    padding: 80px 0;

    @include responsive-down (tablet) {
      padding: 60px 0;
    }

    @include responsive-down (tablet-small) {
      padding: 50px 0;
    }

    &__intro {
      max-width: 400px;
    }

    &__title {
      color: $primary;
      font-size: 25px;

      @include responsive-down (tablet-extra-small) {
        font-size: 22px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 0 22px 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 0 0;

      @include responsive-down (mobile-large) {
        justify-content: flex-start;
      }
    }

    &__item {
      width: calc(100% / 3 - 50px);
      padding: 0 0 60px;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;

      @include responsive-down (mobile-extra-large) {
        width: calc(100% / 3 - 30px);
      }

      @include responsive-down (mobile-large) {
        width: 100%;
        padding: 0 0 20px;
      }
    }

    &__itemtitle {
      color: $primary;
      font-size: 16px;
      line-height: 25px;
      padding: 10px 0;
      max-width: 290px;

      @include responsive-down (tablet-small) {
        font-size: 14px;
        line-height: 20px;
        width: 185px;
      }

      @include responsive-down(mobile-large) {
        width: 400px;
        max-width: none;
      }

      @include responsive-down(mobile-small) {
        width: 340px;
      }

      @include responsive-down(mobile-extra-small) {
        width: 100%;
      }

      i {
        font-size: 10px;
        font-weight: 700;
      }
    }

    &__itemimg {
      width: auto;
      height: 50px;
    }
  }

  &-politics {
    background-color: $secondary-light03;
    padding: 70px 0;

    @include responsive-down (tablet) {
      padding: 60px 0;
    }

    @include responsive-down (tablet-small) {
      padding: 50px 0;
    }

    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include responsive-down(mobile-large) {
        display: block;
      }
    }

    &__title {
      color: $primary;
      font-size: 25px;

      @include responsive-down (tablet-extra-small) {
        font-size: 22px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 0 22px 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__text {
      width: 400px;

      @include responsive-down(tablet) {
        width: 70%;
      }

      @include responsive-down(mobile-large) {
        width: 90%;
      }
    }

    &__private {
      width: 50%;

      &:nth-child(n+3) {
        margin-top: 40px;
      }

      a {
        text-decoration: none;
      }

      @include responsive-down(mobile-large) {
        width: 100%;

        &:first-child {
          padding: 0 0 50px;
        }
      }
    }
  }

  &-responsability {
    display: flex;
    justify-content: space-between;

    @include responsive-down (tablet-extra-small) {
      display: block;
    }

    &__intro {
      width: 480px;
      padding: 85px 0;

      @include responsive-down (tablet) {
        padding: 60px 0;
      }

      @include responsive-down (tablet-small) {
        padding: 50px 0;
      }

      @include responsive-down (tablet-extra-small) {
        padding: 50px 0 0;
      }

      @include responsive-down(mobile-large) {
        width: 100%;
      }

      p {
        margin: 26px 0;
      }
    }

    &__title {
      color: $primary;
      padding: 0 0 20px;
      font-size: 25px;

      @include responsive-down (tablet-extra-small) {
        font-size: 22px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 0 22px 0;
        margin: 0;
        padding: 0;
      }

      @include responsive-down(mobile-extra-small) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__subtitle {
      color: $primary;
      font-weight: 700;
      line-height: 18px;
    }

    &__list {
      width: 630;
      padding: 85px 0;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;

      @include responsive-down (tablet-extra-large) {
        flex-wrap: wrap;
        width: 500px;
        display: flex;
        padding: 85px 0 0 80px;
        justify-content: flex-start;
        align-content: flex-start;
      }

      @include responsive-down (tablet) {
        padding: 60px 0 60px 80px;
        width: 330px;
        margin: auto;
      }

      @include responsive-down (tablet-small) {
        padding: 50px 0;
        width: 220px;
      }

      @include responsive-down (tablet-extra-small) {
        width: 100%;
        padding: 20px 0 50px;
        justify-content: space-between;
      }

      @include responsive-down(mobile-large) {
        justify-content: flex-start;
      }

      img {
        width: 110px;
        height: 110px;
        margin: 10px;

        @include responsive-down(tablet) {
          width: 90px;
          height: 90px;
        }

        @include responsive-down(tablet-extra-small) {
          height: 110px;
          width: 110px;
          margin: 0 10px 0 0;

          &:last-child {
            margin: 0;
          }
        }

        @include responsive-down(mobile-large) {
          width: 90px;
          height: 90px;
          margin: 0 10px 10px 0;

          &:last-child {
            margin: 0 10px 10px 0;
          }
        }
      }
    }
  }

  &-parallax {
    height: 375px;
    width: 100%;
    overflow: hidden;

    @include responsive-down(tablet-small) {
      height: 300px;
    }

    @include responsive-down(mobile) {
      height: 200px;
    }

    &-img,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
