/* Layouts: search-single
--------------------------------------------------------------------------------------------------------------------- */
.search-single {
  width: 100%;
  padding: 0;
  margin: 0 0 30px 0;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }

  &__heading {
    width: 17.5%;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    color: $primary;

    &--first {
      width: 30%;
    }
  }

  &__body,
  &__item {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid $secondary-dark-grey;
  }

  &__text {
    width: 17.5%;
    margin: 0;
    padding: 0 30px 0 0;
    font-size: 11px;
    line-height: 14px;

    &--first {
      width: 30%;
    }
  }
}
