/* Block: RSC Banner
--------------------------------------------------------------------------------------------------------------------- */
.rscbanner {
  min-height: 200px;
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  position: relative;
  overflow: hidden;

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      min-height: 100%;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
    }
  }

  &__content {
    max-width: 500px;
    height: 100%;
    padding: 50px;

    @include responsive-down(tablet) {
      padding: 50px 25px;
    }
  }

  &__title {
    font-weight: 600;
    color: $white;
    margin: 20px 0;
    font-size: 24px;

    @include responsive-down(tablet) {
      font-size: 22px;
    }
  }

  &__description {
    margin: 0 0 20px;
    color: $white;
  }
}
