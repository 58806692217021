/* Block: Access area
--------------------------------------------------------------------------------------------------------------------- */
.congratpopup {
  border-radius: 10px;
  border-bottom-right-radius: 0;
  box-shadow: $box-shadow-generic;
  padding: 20px;
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 25px;
  width: 450px;
  min-height: 100px;
  background-color: $white;
  visibility: visible;
  opacity: 1;
  z-index: 9;
  transform: translateX(0);

  @include transition (all 0.5s ease-in);

  @include responsive-down(mobile) {
    bottom: 20px;
    right: 5%;
    width: 90%;
  }

  &__cake {
    font-size: 30px;
    color: $primary-blue;
  }

  &__text {
    padding: 0 0 0 15px;
  }

  &__title {
    margin: 0 0 5px;
    color: $primary;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &__names {
    font-size: 11px;
    margin: 0;
    line-height: 18px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 900;
    font-size: 12px;
    color: $grey;
    background-color: $white;
    border-radius: 100%;
    padding: 5px;

    @include transition (all 0.25s ease-in);

    &:hover {
      @include responsive-up(tablet-large) {
        background-color: $secondary-light;
        color: $primary;

        @include transition (all 0.25s ease-in);
      }
    }
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
    transform: translateX(200%);

    @include transition (all 0.5s ease-in);
  }
}
