.faqs {
  .tns-ovh {
    @include responsive-down(mobile) {
      width: calc(100% + 20px);
      margin-right: -20px;
    }
  }

  &__slider {
    display: flex;
    margin: 50px 0 20px;

    @include responsive-down(tablet) {
      margin: 20px 0;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    position: relative;
    min-height: 180px;
    width: 100%;
    padding: 20px;

    @include responsive-up(desktop-large) {
      min-height: 220px;
    }

    @include responsive-down(tablet) {
      min-height: 140px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      z-index: 1;

      @include transition(all 0.25s ease-in-out);
    }

    &::before {
      content: "";
      background-color: $primary;
      mix-blend-mode: multiply;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &:hover {
      text-decoration: none;

      @include responsive-up(tablet-extra-large) {
        transform: translateY(-10px);

        @include transition(all 0.25s ease-in-out);

        &::before {
          mix-blend-mode: initial;
        }

        img {
          opacity: 0;

          @include transition(all 0.25s ease-in-out);
        }

        .faqs__text {
          color: $white;

          @include transition(all 0.25s ease-in-out);
        }
      }
    }
  }

  &__title {
    z-index: 3;
    color: $white;
    font-size: 20px;
    font-weight: 800;

    @include responsive-down(tablet) {
      font-size: 16px;
    }
  }

  &__text {
    z-index: 3;
    text-transform: uppercase;
  }

  .tns-controls {
    display: none;
  }
}
