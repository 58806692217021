/* Layouts: Training
--------------------------------------------------------------------------------------------------------------------- */
.training {
  .error {
    display: flex;
    padding: 2em 0;
    background: $white;

    &__text {
      width: 100%;
      margin: 0;
      padding: 10px 20px;
      background: $primary-red;
      color: $white;
    }
  }

  &__areas {
    padding: 6em 0 3em;

    @include responsive-down(tablet) {
      padding: 3em 0 0;
    }

    &-text {
      &-title {
        color: $primary;
        margin-bottom: 1em;
      }
    }

    &-cards {
      @include responsive-down(tablet) {
        margin: 0 -10px;
      }

      .card {
        width: calc(100% / 4 - 40px);
        margin: 20px;
        padding: 30px;

        @include responsive-down(tablet-large) {
          width: calc(100% / 4 - 20px);
          margin: 10px;
        }

        @include responsive-down(tablet) {
          width: calc(100% / 2 - 20px);
          margin: 10px;
          padding: 30px;
          min-height: 180px;
        }

        @include responsive-down(mobile) {
          padding: 15px;
        }

        &__title {
          font-size: 22px;

          @include responsive-down(tablet) {
            font-size: 15px;
          }
        }

        .btn {
          @include responsive-down(tablet) {
            margin-top: 15px;
          }
        }
      }
    }
  }

  &__agreements {
    padding: 3em 0;

    &-text {
      &-title {
        margin-bottom: 1em;
        color: $primary;

        @include responsive-down(tablet) {
          max-width: 75%;
        }
      }
    }

    &-categories {
      display: flex;
      width: calc(100% + 40px);
      margin: -20px;

      @include responsive-down(tablet) {
        flex-direction: column;
      }

      &-item {
        width: calc(100% / 2 - 40px);
        margin: 20px;
        padding: 40px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        color: $white;
        position: relative;

        @include responsive-down(tablet) {
          width: calc(100% - 40px);
          margin: 20px;
          margin-bottom: 0;
          padding: 20px;
        }

        div {
          display: flex;
          align-items: center;

          img {
            width: 30px;
            margin-right: 10px;
          }

          .item__title {
            font-size: 22px;
          }
        }

        &:first-child {
          background-color: $primary-blue;
        }

        &:nth-child(2) {
          background-color: $primary;
        }

        .item__text {
          color: $white;
          margin-bottom: 2em;
        }

        .item__logo {
          position: absolute;
          bottom: 30px;
          right: 30px;
          max-width: 80px;

          @include responsive-down(tablet) {
            bottom: 20px;
            right: 20px;
          }
        }

        .btn {
          &--white {
            &--border {
              background-color: transparent;
              border: 1px solid $white;
              color: $white;
            }
          }
        }
      }
    }
  }

  &__loan {
    padding: 3em 0;

    @include responsive-down(tablet) {
      min-height: 300px;
    }

    @include responsive-down(mobile) {
      min-height: 600px;
    }

    .container--custom-flex {
      @include responsive-down(tablet) {
        padding: 0 20px;
      }

      @include responsive-down(mobile) {
        padding: 0 40px;
      }
    }
  }

  &__faqs {
    &-content {
      img {
        top: 0;
      }
    }
  }

  &__unemployed {
    width: 100%;

    &-text {
      &-content {
        h3 {
          font-size: 22px;
        }
      }

      .link {
        color: $primary;
        text-transform: initial;
        text-decoration: underline;
        margin-right: 0.5em;
        margin-top: 1em;
        display: inline-block;

        &:hover {
          @include responsive-up(tablet) {
            text-decoration: none;
            color: $primary-blue;
          }
        }
      }
    }
  }
}

.map-container {
  perspective: 1000px;
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  &__child {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transition: transform 0.05s linear;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;

      &:nth-child(2) {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateZ(20px);
      }
    }
  }
}

.page-template-page_training {
  .training__agreements {
    &-categories {
      &-item {
        &:first-child {
          .btn {
            &--white {
              &--border {
                span,
                i {
                  color: $white;
                }

                &:hover {
                  border: 1px solid $white;

                  @include responsive-up(tablet-extra-small) {
                    span,
                    i {
                      color: $primary;
                    }

                    &::before {
                      background-color: $white;
                    }

                    &::after {
                      background-color: $white;
                    }
                  }
                }
              }
            }
          }
        }

        &:nth-of-type(2) {
          .item__logo {
            max-width: 130px;
          }

          .btn {
            &--white {
              &--border {
                span,
                i {
                  color: $white;
                }

                &:hover {
                  border: 1px solid $primary;

                  @include responsive-up(tablet) {
                    span,
                    i {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .flexible__type-11-form .form {
    .forms__group--legal label {
      color: inherit;
    }
  }
}
