/* Landing: Footer
--------------------------------------------------------------------------------------------------------------------- */
.landing-footer {
  padding: 50px 0;

  @include responsive-down(tablet) {
    padding: 20px 0 100px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include responsive-down(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__corporate {
    display: flex;
    align-items: center;

    @include responsive-down(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__logo {
    width: 200px;
    margin-right: 20px;

    @include responsive-down(tablet) {
      margin-right: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__copy {
    display: flex;
    align-items: center;

    @include responsive-down(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .link,
  span {
    text-transform: inherit;
    color: grey;
    font-size: 12px;
    letter-spacing: 0;
    margin: 0 20px;

    @include responsive-down(tablet-extra-large) {
      margin: 0 5px;
    }

    @include responsive-down(tablet) {
      margin: 10px 0;
    }
  }
}
