/* Block: Download PDF
--------------------------------------------------------------------------------------------------------------------- */
.downloadpdf {
  border-radius: 10px;
  box-shadow: $box-shadow-generic;
  height: 150px;
  width: 100%;
  padding: 20px 80px 20px 20px;
  display: inline-flex;
  position: relative;

  &__img {
    height: 100%;
    width: 80px;
    margin: 0 20px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    display: inline-block;
    margin: 0;
  }

  &__icon {
    font-size: 20px;
    color: $primary-blue;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
