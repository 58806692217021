/* Custom styles
--------------------------------------------------------------------------------------------------------------------- */

@import "_variables/variables";
@import "_variables/mixins";
@import "_variables/_responsive/up";
@import "_variables/_responsive/down";
@import "_variables/_responsive/only";
@import "_pages/home";
@import "_pages/login";
@import "_pages/page-simple";
@import "_pages/404";
@import "_pages/_flexible";
@import "_pages/_training";
@import "_pages/_training-category";
@import "_pages/_training-single";
@import "_pages/about-us";
@import "_pages/view-search";
@import "_pages/centers";
@import "_pages/faqs";
@import "_pages/single-mas-cpt-specialties";
@import "_pages/health";
@import "_pages/page-specialities";
@import "_pages/blog";
@import "_pages/single";
@import "_blocks/post-area";
@import "_blocks/textgroup";
@import "_blocks/access";
@import "_blocks/congratpopup";
@import "_blocks/cardsarea";
@import "_blocks/rscbanner";
@import "_blocks/workercard";
@import "_blocks/downloadpdf";
@import "_blocks/areanextpost";
@import "_blocks/faqs";
@import "_blocks/collaborators";
@import "_blocks/files";
@import "_partials/footer";
@import "_partials/cookies";
@import "_area/footer";
@import "_area/home";
@import "_area/post-gallery";
@import "_landing/landing";
@import "_landing/footer";
