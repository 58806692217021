.collaborators {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 25px -10px 50px;

  @include responsive-up(desktop-extra-small) {
    width: calc(100% + 40px);
    margin: 25px -20px 50px;
  }

  @include responsive-down(tablet-extra-small) {
    width: calc(100% + 40px);
    margin: 25px -20px 50px;
  }

  @include responsive-down(mobile-small) {
    width: calc(100% + 20px);
    margin: 25px -10px 50px;
  }

  .collaborator {
    width: calc(100% / 5 - 20px);
    margin: 0 10px;

    @include responsive-up(desktop-extra-small) {
      width: calc(100% / 5 - 40px);
      margin: 0 20px;
    }

    @include responsive-down(tablet-extra-large) {
      width: calc(100% / 3 - 20px);
    }

    @include responsive-down(tablet-extra-small) {
      width: calc(100% / 2 - 40px);
      margin: 0 20px;
    }

    @include responsive-down(mobile-small) {
      width: calc(100% - 20px);
      margin: 0 10px;
    }

    &__img {
      width: auto;
      max-height: 50px;
      margin-top: 25px;

      @include responsive-down(tablet-extra-small) {
        max-height: 35px;
      }

      @include responsive-down(mobile-small) {
        max-height: 50px;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      margin: 10px 0 0;

      @include responsive-down(mobile) {
        font-size: 14px;
      }
    }

    &:hover {
      text-decoration: none;

      @include responsive-up(tablet-large) {
        color: $primary;

        @include transition(all 0.2s ease-in-out);
      }
    }
  }
}
