/* Layouts: Login
--------------------------------------------------------------------------------------------------------------------- */

.login {
  display: flex;

  .container--medium {
    display: flex;

    @include responsive-down(tablet-small) {
      display: block;
    }

    &::after {
      content: '';
      width: 50%;
      height: 100%;
      background-color: $secondary-light03;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;

      @include responsive-down(tablet-small) {
        display: none;
      }
    }
  }

  &-left {
    width: 50%;
    padding: 60px 150px 0 0;

    @include responsive-down(desktop-extra-small) {
      padding: 60px 100px 0 0;
    }

    @include responsive-down(tablet-extra-large) {
      padding: 60px 80px 0 0;
    }

    @include responsive-down(tablet-large) {
      padding: 60px 50px 0 0;
    }

    @include responsive-down(tablet-small) {
      padding: 35px 0 30px;
    }

    .title20px {
      margin-bottom: 35px;

      @include responsive-down(tablet) {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }

    .btn {
      display: block;
      margin: 15px 0;
      width: 100%;
    }

    &__checkpassword {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      @include responsive-down(mobile) {
        display: block;
        margin-top: 10px;
      }

      label {
        @include responsive-down(tablet-small) {
          font-size: 12px;
        }
      }

      &-forget {
        align-items: center;
        display: flex;

        i {
          font-size: 10px;
          color: $primary;
          margin-right: 4px;

          @include responsive-down(tablet-small) {
            font-size: 14px;
            margin-right: 8px;
          }

          @include responsive-down(mobile) {
            font-size: 16px;
            margin-right: 10px;
          }
        }

        .link {
          font-size: 10px;
          text-decoration: underline;

          @include responsive-down(tablet-small) {
            font-size: 12px;
          }
        }
      }
    }

    &__banner {
      background-color: $services-blue-light03;
      padding: 25px 16px;
      position: relative;

      p {
        font-size: 14px;
        margin: 0;
        padding: 0 0 0 22px;
        line-height: 18px;
      }

      i {
        position: absolute;
        top: 26px;
        font-size: 16px;
        color: $primary;
      }

      a {
        color: $black;
        text-decoration: underline;

        &:hover {
          @include responsive-up(tablet) {
            color: $primary;
          }
        }
      }
    }

    @include responsive-down(tablet-small) {
      width: 100%;
    }
  }

  &-right {
    width: 50%;
    padding: 60px 0 60px 90px;

    @include responsive-down(desktop-extra-small) {
      padding: 60px 0 60px 70px;
    }

    @include responsive-down(tablet-extra-large) {
      padding: 60px 40px 60px 50px;
    }

    @include responsive-down(tablet-small) {
      width: calc(100% + 60px);
      margin: 0 -30px;
      padding: 30px 30px 30px;
      background-color: $secondary-light03;
    }

    @include responsive-down(mobile-large) {
      width: calc(100% + 30px);
      margin: 0 -15px;
      padding: 30px 15px 30px;
      background-color: $secondary-light03;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      margin: 7px 0;

      @include responsive-down(tablet-small) {
        font-size: 14px;
        line-height: 20px;
        margin: 7px 0;
      }

      @include responsive-down(mobile-small) {
        margin: 5px 0;
      }
    }

    &__roles {
      position: relative;

      @include responsive-down(tablet-small) {
        padding: 0 20px 0 0;
      }

      .title20px {
        margin-bottom: 35px;

        @include responsive-down(tablet-small) {
          font-size: 22px;
          margin-bottom: 30px;
          line-height: 28px;
        }
      }

      i {
        position: absolute;
        color: $primary;
        font-size: 20px;
        font-weight: 400;
      }

      &--infor {
        padding: 0 0 20px 40px;

        @include responsive-down(mobile-small) {
          padding: 0 0 15px 40px;
        }

        &__title {
          font-size: 14px;
          color: $primary;
          font-weight: 400;

          @include responsive-down(tablet-small) {
            font-weight: 700;
            font-size: 16px;
          }
        }
      }
    }

    &__registration {
      padding-top: 32px;

      p {
        font-size: 13px;
        line-height: 18px;

        @include responsive-down(tablet-small) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .mas-authentication__result {
    display: none;
    position: relative;

    p {
      color: $primary;
      font-size: 14px;
      margin: 10px 0 10px 30px;
    }

    i {
      color: $primary;
      position: absolute;
      top: 1px;
      font-size: 20px;
    }

    &--info,
    &--error,
    &--success {
      display: block;
    }

    &--error {
      p,
      i {
        color: red;
      }
    }

    &--success {
      p,
      i {
        color: green;
      }
    }
  }

  .mas-authentication__group--server {
    display: none;
  }
}
