/* Block: Post-area
--------------------------------------------------------------------------------------------------------------------- */
.textgroup {
  padding: 0 0 15px;
  width: 300px;
  border-radius: 10px;
  border-bottom-left-radius: 0;

  @include responsive-down(tablet) {
    width: 100%;
  }

  @include responsive-down(mobile) {
    width: 100%;
    padding: 0;
  }

  &__title {
    color: $primary;
    font-size: 25px;
    line-height: 30px;

    @include responsive-down(mobile) {
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  &__description {
    margin: 12px 0;

    @include responsive-down(tablet) {
      margin: 8px 0 4px;
    }

    p {
      @include responsive-down(tablet) {
        margin: 0;
      }
    }
  }

  .link {
    font-size: 12px;
  }
}
