/* Page: Flexible
--------------------------------------------------------------------------------------------------------------------- */
.flexible {
  &__type {
    &-0 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 60px 30px 30px;
      max-width: 50%;
      margin: 0 auto;
      text-align: center;

      @include responsive-down(tablet) {
        max-width: 75%;
      }

      @include responsive-down(mobile) {
        max-width: 100%;
        padding: 30px 0;
      }

      &-title {
        color: $primary;
        margin-top: 0;
        margin-bottom: 0.5em;
        font-size: 25px;
        text-align: center;
      }

      &-text {
        margin-top: 0;
        color: $primary;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
      padding: 3em 0;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
        flex-direction: column;
        width: 100%;
      }

      .title {
        margin-bottom: 1em;
        color: $primary;
      }

      &-text {
        width: 50%;
        padding-right: 10%;

        @include responsive-down(desktop-small) {
          padding-right: 5%;
        }

        @include responsive-down(tablet) {
          width: 100%;
          padding-right: 0;
        }

        &-icon {
          width: 23px;
        }

        &-content {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #222;

          h1 {
            font-weight: 700;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin: 0.5em 0 1em;
            color: $primary;
          }
        }

        .flexible__buttons-container {
          margin: 1em 0;
          display: flex;
          flex-wrap: wrap;

          &:empty {
            margin: 0;
          }
        }

        &-download {
          display: flex;
          align-items: center;

          i {
            display: inline-block;
            font-size: 15px;
            color: $white;
            margin-right: 5px;
            font-weight: 500;
          }

          a {
            font-weight: 500;
            display: inline-flex;
            padding: 12px 0;
          }
        }

        &-link {
          display: flex;
          align-items: center;

          i {
            display: inline-block;
            font-size: 7px;
            color: $white;
            margin-left: 5px;
            font-weight: 500;
          }

          a {
            font-weight: 500;
            display: flex;
            align-items: center;
            margin: 0 10px 10px 0;
          }
        }

        &-header {
          display: flex;
          align-items: flex-end;

          .flexible__type-1-text-icon {
            width: 40px;
            margin-right: 10px;
          }

          h2 {
            color: $primary;
          }
        }
      }

      &-image {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include responsive-down(tablet) {
          width: 100%;
          justify-content: center;
          margin-top: 20px;
        }

        img {
          width: 55%;
          object-fit: cover;
          display: flex;
          align-items: center;
          justify-content: center;

          @include responsive-down(tablet) {
            width: 50%;
          }

          @include responsive-down(mobile) {
            width: 60%;
          }
        }

        .animation {
          max-width: 55%;
          margin-left: 25%;

          @include responsive-down(tablet) {
            margin: auto;
            margin-left: initial;
          }
        }

        &--full {
          img {
            width: 100%;
          }
        }
      }

      &-video {
        width: 50%;
        position: relative;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        overflow: hidden;
        box-shadow: $box-shadow-generic;

        @include responsive-down(tablet-extra-large) {
          width: 50%;
        }

        @include responsive-down(tablet) {
          width: 100%;
          order: 1;
          justify-content: center;
        }

        .play-video {
          width: 50px;
          height: 50px;
          display: block;
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 25px);
          z-index: 8;
          cursor: pointer;

          svg {
            width: 50px;
            height: 50px;
          }

          // Spin animation
          @keyframes spin {
            to { transform: rotate(360deg); }
          }

          .stroke-solid {
            stroke-dashoffset: 0;
            stroke-dasharray: 300;
            stroke-width: 4px;
            transition: stroke-dashoffset 1s ease, opacity 1s ease;
          }

          .icon {
            transform: scale(0.8);
            transform-origin: 50% 50%;
            transition: transform 200ms ease-out;
          }

          &:hover {
            @include responsive-up(tablet) {
              .stroke-solid {
                opacity: 1;
                stroke-dashoffset: 300;
              }

              .icon {
                transform: scale(0.9);
              }
            }
          }
        }

        img {
          width: 100%;
          object-fit: cover;
          display: flex;
          top: 0;
          left: 0;
          height: 100%;
        }

        video {
          width: 100%;
          height: auto;
        }

        &--active {
          .play-video {
            display: none;
          }

          img {
            display: none;
          }

          video {
            display: block;
          }
        }
      }

      &--left {
        .flexible__type-1-text {
          order: 2;
          padding-right: 0;
          padding-left: 10%;

          @include responsive-down(desktop-small) {
            padding-left: 5%;
          }

          @include responsive-down(tablet) {
            order: 1;
            padding-left: 0;
          }
        }

        .flexible__type-1-image,
        .flexible__type-1-video {
          order: 1;
          justify-content: flex-start;

          @include responsive-down(tablet) {
            order: 2;
            justify-content: center;
          }
        }
      }

      &--small {
        width: 80%;

        @include responsive-down(tablet) {
          width: 100%;
        }

        .flexible__type-1-image,
        .flexible__type-1-video {
          img,
          svg {
            width: 65%;

            @include responsive-down(tablet) {
              width: 60%;
            }
          }
        }

        .flexible__type-1-image--full {
          img,
          svg {
            width: 100%;
          }
        }
      }

      &-18 {
        align-items: stretch;

        .flexible__type-1 {
          &-image {
            overflow: hidden;
            border-radius: 15px;
            border-bottom-left-radius: 0;
            box-shadow: $box-shadow-generic;
            min-height: 375px;
            max-height: 375px;

            img {
              width: auto;
              min-width: 100%;
              min-height: 100%;
            }
          }
        }
      }
    }

    &-2 {
      padding: 3em 0;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $primary;
      }

      &-title {
        @include responsive-down(tablet) {
          margin-bottom: 1em;
        }
      }

      &-cards {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -15px 0;

        @include responsive-down(tablet) {
          margin: 0;
        }

        .card {
          width: calc(100% / 5 - 30px);
          margin: 15px;

          @include transition (all 0.4s ease);

          @include responsive-down(desktop-small) {
            width: calc(100% / 5 - 30px);
            margin: 15px;
          }

          @include responsive-down(tablet-extra-large) {
            width: calc(100% / 4 - 40px);
          }

          @include responsive-down(tablet-large) {
            width: calc(100% / 3 - 40px);
          }

          @include responsive-down(tablet) {
            width: 100%;
            margin: 10px 0;
            height: auto;
            overflow: hidden;
          }

          & > div {
            width: 100%;
            height: 100%;
            background-color: $light-grey;
            padding: 30px 20px;
            border-radius: 10px;

            @include responsive-down(tablet) {
              padding: 20px;
            }
          }

          &__icon {
            width: 20px;
            margin-right: 10px;
          }

          &__header {
            display: flex;
            align-items: center;
            position: relative;

            @include responsive-down(tablet) {
              padding-right: 20px;
            }
          }

          &__title {
            color: $primary;
            font-weight: 700;
            font-size: 18px;

            @include responsive-down(desktop-small) {
              font-size: 16px;
            }

            @include responsive-down(tablet) {
              font-size: 20px;
              font-weight: 500;
            }

            @include responsive-down(mobile) {
              font-size: 16px;
            }
          }

          &__arrow {
            display: none;

            @include responsive-down(tablet) {
              display: inline-block;
              position: absolute;
              right: 20px;
              top: 50%;
              cursor: pointer;
            }

            &-left {
              position: absolute;
              background-color: transparent;
              top: 0;
              left: 0;
              width: 12px;
              height: 2px;
              display: block;
              transform: rotate(45deg);
              float: right;

              &::after {
                content: "";
                background-color: $primary;
                width: 12px;
                height: 2px;
                display: block;
                float: right;
                transition: all $duration $easing;
                z-index: -1;
                border-radius: 50px;
              }
            }

            &-right {
              position: absolute;
              background-color: transparent;
              top: 0;
              left: 7.5px;
              width: 12px;
              height: 2px;
              display: block;
              transform: rotate(-45deg);
              float: right;

              &::after {
                content: "";
                background-color: $primary;
                width: 12px;
                height: 2px;
                display: block;
                float: right;
                transition: all $duration $easing;
                z-index: -1;
                border-radius: 50px;
              }
            }
          }

          &__text {
            font-size: 14px;
            line-height: 20px;

            @include responsive-down(desktop-small) {
              font-size: 13px;
              line-height: 18px;
            }

            @include responsive-down(tablet) {
              padding-top: 0;
              transition: opacity 0.4s ease;
              opacity: 0;
              height: 0;
            }

            p {
              font-size: 14px;

              @include responsive-down(tablet) {
                margin: 0;
                height: 0;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &--opened {
            @include responsive-down(tablet) {
              max-height: 500px;

              @include transition (all 0.4s ease);
            }

            .card__arrow-left::after {
              @include responsive-down(tablet) {
                transform-origin: center center;
                transform: rotate(-90deg);
              }
            }

            .card__arrow-right::after {
              @include responsive-down(tablet) {
                transform-origin: center center;
                transform: rotate(90deg);
              }
            }

            .card__text {
              @include responsive-down(tablet) {
                height: auto;
                padding-top: 1.5em;
                opacity: 1;
                transition: opacity 0.2s ease;
              }

              p {
                @include responsive-down(tablet) {
                  margin: 1em 0;
                  height: auto;
                }
              }
            }
          }
        }
      }

      &--version2 {
        .flexible__type-2-cards {
          gap: 30px;

          @include responsive-down(tablet) {
            gap: 0;
          }

          .card {
            width: calc(100% / 5 - 60px);

            @include responsive-down(tablet-extra-large) {
              width: calc(100% / 5 - 10px);
            }

            @include responsive-down(tablet) {
              width: 100%;
              margin: 0;
              height: auto;
              overflow: hidden;
              padding: 10px 0;
              border-bottom: 1px solid $primary;

              &:first-child {
                border-top: 1px solid $primary;
              }
            }

            & > div {
              background-color: transparent;
              padding: 0;
            }

            &__icon {
              width: auto;
              margin-right: 10px;
              height: 50px;
              max-width: 50px;
              object-fit: contain;

              @include responsive-down(tablet) {
                max-width: 40px;
              }
            }

            &--opened {
              @include responsive-down(tablet) {
                .card__text p {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

    &-3 {
      display: flex;
      padding: 3em 0;

      @include responsive-down(tablet) {
        flex-wrap: wrap;
        padding: 1.5em 0;
      }

      &-content {
        width: 40%;
        padding-right: 10%;

        @include responsive-down(tablet) {
          width: 50%;
        }

        @include responsive-down(mobile) {
          width: 100%;
          padding-right: 0;
          margin-bottom: 20px;
        }

        .flexible__icon {
          width: 25px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary;
          line-height: 1.5em;
        }
      }

      &-items {
        width: calc(60% + 40px);
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;

        @include responsive-down(tablet) {
          flex-wrap: nowrap;
          width: 100%;
          margin: 0;
        }

        @include responsive-down(mobile) {
          flex-wrap: wrap;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: calc(100% / 3 - 40px);
          margin: 0 20px;

          @include responsive-down(tablet) {
            width: 100%;
            margin: 10px 0;
            align-items: center;
          }

          img,
          .animation {
            width: auto;
            height: 100px;
            margin-bottom: 20px;
          }

          &__content {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: $primary;

              @include responsive-down(tablet) {
                text-align: center;
              }
            }

            p {
              @include responsive-down(tablet) {
                text-align: center;
              }
            }
          }
        }
      }

      &--presu {
        @include responsive-down(tablet-large) {
          flex-wrap: wrap;
          padding: 1.5em 0;
        }

        .flexible__type-3-content {
          margin: 30px 0 0;

          @include responsive-down(tablet-large) {
            width: 100%;
            padding: 0;
          }

          .flexible__content {
            max-width: 300px;

            h2 {
              line-height: 1.2;
            }

            @include responsive-down(tablet-large) {
              max-width: none;
            }

            @include responsive-down(tablet) {
              h2 {
                text-align: center;
              }
            }
          }
        }

        .flexible__type-3-items {
          width: calc(60% + 580px);

          @include responsive-down(tablet-large) {
            flex-wrap: nowrap;
            width: 100%;
            margin: 40px 0 0;
          }

          .item {
            @include responsive-down(tablet-large) {
              margin: 0 40px 0 0;
            }

            @include responsive-down(tablet) {
              width: 100%;
              margin: 10px 10px 30px;
              align-items: center;
            }

            &__content {
              p {
                &:first-child {
                  color: $primary;
                }
              }
            }
          }

          @include responsive-down(mobile) {
            flex-wrap: wrap;
          }
        }

        .item {
          &__link {
            margin: 12px 0 0;
          }

          img {
            height: 60px;
            margin: 0 0 20px;
          }
        }
      }

      &__container {
        background-color: $light-grey;

        .container {
          max-width: 1450px;
        }
      }

      &--version2 {
        flex-direction: column;
        align-items: center;
        gap: 40px;

        @include responsive-down(tablet-small) {
          gap: 0;
        }

        .flexible__type-3 {
          &-items {
            width: 100%;
            column-gap: 30px;
            row-gap: 20px;
            flex-wrap: wrap;

            @include responsive-down(tablet-small) {
              flex-direction: column;
              row-gap: 0;
            }

            .item {
              width: calc(100% / 2 - 15px);
              flex-direction: row;
              background-color: $white;
              padding: 20px;
              margin: 0;
              gap: 20px;
              border-radius: 10px;
              align-items: center;

              @include responsive-down(tablet-small) {
                background-color: transparent;
                padding: 15px 0;
                width: 100%;
                gap: 13px;
                justify-content: center;
                flex-direction: column !important;
              }

              &__icon,
              .animation {
                margin: 0;
                height: 50px;
              }

              &__content {
                padding: 0;

                p {
                  font-size: 20px;
                  margin: 0;
                  text-align: left;

                  @include responsive-down(tablet-large) {
                    font-size: 16px;
                  }

                  @include responsive-down(tablet-small) {
                    font-size: 16px;
                    text-align: center;
                  }
                }
              }
            }
          }

          &-content {
            padding: 0;

            .flexible__content {
              text-align: center;
              padding: 0;

              h2,
              h3,
              h4,
              h5 {
                text-align: center;
              }
            }
          }
        }
      }
    }

    &-4 {
      position: relative;
      margin: 10em 0 3em;
      min-height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive-down(tablet-large) {
        min-height: 300px;
        margin: 1.5em 0;
      }

      @include responsive-down(mobile) {
        min-height: 600px;
        margin: 1.5em 0;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background: $primary;
        width: 90%;
        height: 100%;
        left: 0;
        top: 0;
        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;

        @include responsive-down(tablet-large) {
          width: 95%;
        }

        @include responsive-down(mobile) {
          width: calc(100% - 40px);
          border-top-right-radius: 0;
          border-bottom-right-radius: 500px;
          border-bottom-left-radius: 500px;
          margin: 0 20px;
        }
      }

      .container--custom-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include responsive-down(tablet) {
          padding: 4em 20px;
        }

        @include responsive-down(mobile) {
          padding: 30px 40px;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white;
      }

      &-content {
        width: 45%;
        z-index: 8;

        @include responsive-down(tablet-large) {
          width: 40%;
        }

        @include responsive-down(mobile) {
          width: 100%;
        }

        p {
          color: $white;
        }

        .btn {
          &:hover {
            @include responsive-up(tablet) {
              background-color: $primary-blue;
              color: $primary;
            }
          }
        }
      }

      &-image {
        right: 20%;
        position: absolute;
        bottom: -1.4%;
        max-width: 300px;

        @include responsive-down(tablet-large) {
          right: 18%;
          max-width: 250px;
        }

        @include responsive-down(mobile) {
          -webkit-mask-box-image: url(/wp-content/themes/mas/assets/img/circle-mask.svg);
          width: calc(100vw - 40px);
          height: calc(100vw - 40px);
          bottom: -2px;
          top: initial;
          right: 20px;
          max-width: initial;
        }

        img {
          width: 100%;
          object-fit: cover;

          @include responsive-down(mobile) {
            width: 80%;
            margin-left: 30%;
            margin-top: 5%;
          }
        }
      }

      .circle-blue {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        background: $primary-blue;
        position: absolute;
        top: 0;
        z-index: -1;
        left: -25%;

        @include responsive-down(mobile) {
          width: 280px;
          height: 280px;
          left: calc(50% - 140px);
          bottom: 40px;
          top: initial;
        }
      }

      .btn {
        margin: 20px 0 0;
        display: inline-flex;
        align-items: center;

        i {
          display: inline-flex;
          margin-left: 5px;
          font-size: 7px;
        }

        &--blue {
          span,
          i {
            color: $white;
          }

          &::before,
          &::after {
            background: $white;
          }

          &:hover {
            @include responsive-up(tablet) {
              span,
              i {
                color: $primary;
              }
            }
          }
        }
      }
    }

    &-5 {
      padding: 3em 0;
      display: flex;

      @include responsive-down(mobile) {
        flex-wrap: wrap;
        padding: 3em 0;
        width: 100%;
        margin: 0;
      }

      &-content {
        width: 40%;
        padding-right: 10%;

        @include responsive-down(mobile) {
          width: 100%;
          padding-right: 0;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 40px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary;

          @include responsive-down(mobile) {
            width: 80%;
          }
        }

        .text {
          @include responsive-down(mobile) {
            width: 70%;
          }
        }

        .animation,
        img {
          width: 100%;
          height: auto;

          @include responsive-down(mobile) {
            position: absolute;
            top: -50%;
            right: -20%;
            width: 50%;
            height: auto;
            z-index: -1;
          }
        }
      }

      &-faqs {
        width: 60%;

        @include responsive-down(mobile) {
          width: 100%;
        }

        .faqs__list {
          &-text {
            margin: 0;
            height: 0;
            font-size: 12px;
            line-height: 20px;
            opacity: 0;
            transition: opacity 0.5s ease;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &-title {
            position: relative;
            display: inline-block;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
          }

          &-cross {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 7px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: plus-animation-reverse 0.5s ease-out forwards;
            z-index: 8;

            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              border-radius: 4px;
              background: $primary-blue;
            }

            &::before {
              width: 1px;
              height: 15px;
            }

            &::after {
              width: 15px;
              height: 1px;
            }

            &-circle {
              position: absolute;
              right: 0;
              top: -7px;
              width: 30px;
              height: 30px;
              overflow: hidden;
              border-radius: 100px;
              z-index: 7;

              .circle {
                position: relative;
                height: 100%;
                width: 100%;
                border-radius: 100px;
                overflow: hidden;

                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: #ededed;
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  border-radius: 50%;
                  transform: translate3d(0, 100%, 0);
                  transition: transform 0.6s;
                  transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
                }
              }
            }
          }

          &-item {
            height: auto;
            display: flex;
            flex-wrap: wrap;
            border-top: 1px solid $primary;
            padding-top: 25px;
            padding-bottom: 25px;
            margin-bottom: 0;
            cursor: pointer;
            transition: height 0.5s ease;
            position: relative;
            overflow: hidden;

            &:last-child {
              border-bottom: 1px solid $primary;
            }

            &--opened {
              height: auto;
              transition: height 0.5s ease;

              .faqs__list-text.faqs__list-text {
                opacity: 1;
                transition: opacity 0.5s ease;
                margin: 1.5em 3em 0 20px;
                height: auto;
                line-height: 20px;
                font-size: 14px !important;

                @include responsive-down(mobile) {
                  width: 100%;
                }

                p,
                a {
                  font-size: 14px !important;
                  line-height: 20px;

                  @include responsive-down(tablet) {
                    font-size: 14px !important;
                  }
                }

                ul,
                ol {
                  margin: 0 14px 14px;

                  li {
                    font-size: 14px !important;
                    line-height: 25px;
                    margin-bottom: 0;
                    list-style: disc;
                  }
                }

                img {
                  max-width: 100% !important;
                  width: 100%;
                  height: 100%;
                  padding: 15px 0;
                }
              }

              .faqs__list-cross {
                animation: plus-animation 0.5s ease-out forwards;
              }
            }

            &::before {
              position: absolute;
              left: 0;
            }

            &:hover {
              .faqs__list-title {
                @include responsive-up(tablet) {
                  color: $primary-blue;

                  @include transition (all 0.2s ease-in);
                }
              }
            }

            &--hovered {
              .faqs__list-cross-circle {
                .circle {
                  &::before {
                    transform: translate3d(0, 0, 0);
                  }
                }
              }
            }
          }

          &-input {
            width: 15px;
            height: 15px;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 5;
            opacity: 0;
          }
        }
      }

      &-btn {
        margin-top: 25px;

        .btn {
          display: inline-flex;
          align-items: center;

          i {
            display: inline-block;
            font-size: 7px;
            color: $white;
            margin-left: 5px;
            font-weight: 500;
          }
        }
      }
    }

    &-6 {
      margin: 3em 0;
      position: relative;
      display: flex;
      flex-wrap: wrap;

      @include responsive-down(tablet) {
        margin: 1.5em -1.5em 1.5em 0;
      }

      &-text {
        max-width: 40%;
        width: 40%;
        padding-right: 10%;
        margin-bottom: 40px;

        @include responsive-down(tablet) {
          max-width: 60%;
          width: auto;
          padding-right: 0;
          margin-bottom: 20px;
        }

        @include responsive-down(mobile) {
          max-width: 100%;
          width: calc(100% - 20px);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary;
        }

        p {
          margin-bottom: 0;
          font-size: 15px;
        }
      }

      &-link {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 40px;

        @include responsive-down(tablet) {
          width: calc(40% - 1.5em);
          margin-bottom: 20px;
        }

        @include responsive-down(mobile) {
          width: 100%;
          align-items: flex-start;
          justify-content: flex-start;
        }

        a {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: 1px;
        }
      }

      &-articles {
        display: flex;
        min-height: 250px;
        width: calc(100% + 40px);
        margin: 0 -20px;

        @include responsive-down(tablet) {
          display: inherit;
          margin: 0;
          padding-top: 20px;
          width: inherit;
        }

        .article {
          width: calc(100% / 3 - 40px);
          margin: 0 20px;
          position: relative;
          border-radius: 10px;
          border-bottom-left-radius: 0;
          overflow: hidden;

          @include responsive-down(tablet) {
            width: calc(100% - 40px);
            margin: 0;
          }

          &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            display: flex;
            align-items: center;
            justify-content: center;

            @include responsive-down(tablet) {
              width: calc(100% - 20px);
              border-radius: 10px;
              overflow: hidden;
            }

            img {
              width: 100%;
              height: auto;
              object-fit: cover;
              min-height: 100%;
              z-index: 1;

              @include transition (all 0.5s ease 0s);
            }

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background: $primary-headertop;
              opacity: 0.75;
              z-index: 2;

              @include transition (all 0.5s ease 0s);
            }
          }

          &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 30px;
            height: 100%;
            color: $white;

            @include responsive-down(tablet) {
              padding: 20px;
              height: 210px;
            }

            &-category {
              font-size: 14px;

              @include responsive-down(tablet) {
                font-size: 10px;
              }
            }

            &-title {
              margin: 10px 0 15px;
              font-size: 25px;

              @include responsive-down(desktop-small) {
                font-size: 20px;
                margin: 5px 0 10px;
              }
            }

            &-link {
              display: flex;
              align-items: center;
              font-size: 12px;
              letter-spacing: 2px;
              text-transform: uppercase;

              @include transition (all 0.25s ease 0s);

              @include responsive-down(tablet) {
                font-size: 10px;
              }

              i {
                font-size: 7px;
                margin-left: 5px;
                margin-bottom: 2px;
              }

              &:hover {
                @include responsive-up(tablet) {
                  color: $primary-blue;

                  @include transition (all 0.25s ease 0s);
                }
              }
            }
          }

          &:hover {
            @include responsive-up(tablet) {
              text-decoration: none;

              .article__image {
                img {
                  transform: scale(1.2);

                  @include transition (all 0.5s ease 0s);
                }

                &::before {
                  background: $primary;
                  opacity: 1;

                  @include transition (all 0.5s ease 0s);
                }
              }

              .article__content-link {
                color: $primary-blue;

                @include transition (all 0.2s ease 0s);
              }
            }
          }

          .container-flex {
            display: flex;
            align-items: flex-end;
            height: 100%;
            min-height: 300px;

            @include responsive-down(tablet) {
              min-height: 200px;
              width: calc(100% - 20px);
              margin-right: 20px;
            }
          }
        }

        &__nav {
          display: flex;
          align-items: center;
          margin-top: 20px;

          @include responsive-down(tablet) {
            margin-top: 10px;
          }

          i {
            display: inline-block;
            color: $primary-blue;
            font-size: 18px;

            &:first-child {
              margin-right: 10px;
              transform: rotate(180deg);
            }
          }
        }
      }

      div#tns2-ow {
        margin: 0 -20px 0 0;
      }

      .tns-outer {
        width: 100%;

        @include responsive-up(tablet) {
          width: calc(100% + 40px);
        }
      }

      .tns-visually-hidden {
        display: none;
      }
    }

    &-7 {
      padding: 3em 0;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
      }

      &-title {
        max-width: 20%;
        margin-bottom: 40px;
        color: $primary;

        @include responsive-down(desktop-small) {
          max-width: 30%;
        }

        @include responsive-down(tablet) {
          max-width: 100%;
          margin-bottom: 20px;
        }
      }

      &-files {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 40px);
        margin: 0 -20px;

        @include responsive-down(desktop-small) {
          width: calc(100% + 30px);
          margin: 0 -15px;
        }

        @include responsive-down(tablet) {
          width: 100%;
          flex-direction: column;
          margin: 0;
        }

        .file {
          width: calc(100% / 3 - 40px);
          margin: 10px 20px;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          background-color: white;
          border-radius: 5px;
          box-shadow: $box-shadow-generic;

          @include responsive-down(desktop-small) {
            width: calc(100% / 3 - 30px);
            margin: 10px 15px;
          }

          @include responsive-down(tablet) {
            width: calc(100% - 20px);
            margin: 10px;
          }

          &__img {
            width: 50px;
            height: 65px;
            overflow: hidden;

            img {
              width: 50px;
              height: auto;
              object-fit: cover;
              min-height: 100%;

              @include transition (all 0.25s ease-in);
            }
          }

          &__link {
            padding: 10px 20px;
            color: $secondary-dark;
            font-size: 14px;
            font-weight: 500;

            &:hover {
              @include responsive-up(tablet) {
                text-decoration: none;
              }
            }
          }

          &__icon {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 30px;
            position: relative;
            z-index: -1;

            @include responsive-down(tablet) {
              width: 50px;
            }

            &-arrow {
              display: block;
              background: $primary-blue;
              width: 2px;
              height: 25px;
              margin: auto;
              position: relative;
              top: 0;
              left: 0;
              border-radius: 9px;

              @include responsive-down(tablet) {
                height: 20px;
              }

              &::after {
                position: absolute;
                content: '';
                width: 2px;
                height: 10px;
                transform: rotate(45deg);
                background: $primary-blue;
                bottom: 0;
                left: 4px;
                border-radius: 9px;
              }

              &::before {
                position: absolute;
                content: '';
                width: 2px;
                height: 10px;
                transform: rotate(-45deg);
                background: $primary-blue;
                bottom: 0;
                right: 4px;
                border-radius: 9px;
              }

              &--active {
                animation: move 1.5s infinite linear;
              }
            }

            &-frame {
              display: block;
              width: 20px;
              height: 2px;
              background: $primary-blue;
              margin: 0 auto 0 auto;
              border-radius: 9px;
              transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
              position: absolute;
              bottom: 10px;
              left: calc(50% - 10px);
            }

            &.round {
              .file__icon-arrow {
                width: 100px;
                height: 100px;
                animation: none;
                border-radius: 50%;
                background: transparent;
                border: solid 5px $primary-blue;
                box-sizing: border-box;
                top: 21px;

                &::after,
                &::before {
                  visibility: hidden;
                }
              }

              .file__icon-frame {
                transform: rotate(-45deg);
                animation: none;
                top: 70px;
                left: 25px;
                width: 60px;

                &::before {
                  content: '';
                  width: 20px;
                  height: 5px;
                  background: $primary-blue;
                  transform: rotate(90deg);
                  position: absolute;
                  left: -8px;
                  top: -10px;
                  border-radius: 9px;
                }

                &::after {
                  transform: rotate(0);
                  top: 0;
                  right: 0;
                }
              }
            }
          }

          &:hover {
            @include responsive-up(tablet) {
              img {
                transform: scale(1.1);

                @include transition (all 0.25s ease-in);
              }
            }
          }
        }
      }
    }

    &-8 {
      padding: 3em 0;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
      }

      &-text {
        width: 40%;
        margin-bottom: 40px;

        @include responsive-down(desktop-small) {
          width: 50%;
          margin-bottom: 20px;
        }

        @include responsive-down(tablet) {
          width: 100%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary;
        }
      }

      &-cards {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 0 -15px;
        width: calc(100% + 30px);

        @include responsive-down(tablet) {
          width: calc(100% + 20px);
          margin: 0 -10px;
        }

        .card {
          width: calc(100% / 5 - 30px);
          margin: 15px;
          padding: 30px;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          box-shadow: $box-shadow-generic;
          border-radius: 5px;
          border-bottom-left-radius: 0;

          @include responsive-down(desktop-small) {
            width: calc(100% / 5 - 30px);
            margin: 15px;
            padding: 20px;
          }

          @include responsive-down(tablet-extra-large) {
            width: calc(100% / 3 - 30px);
          }

          @include responsive-down(tablet-extra-small) {
            width: calc(100% / 2 - 20px);
          }

          @include responsive-down(tablet) {
            width: calc(100% - 20px);
            margin: 10px;
            padding: 30px;
          }

          &__img {
            width: 30px;
          }

          &__title {
            margin-top: 10px;
            color: $primary;
            font-size: 20px;
            font-weight: 700;
            line-height: normal;

            @include responsive-down(tablet) {
              font-size: 18px;
            }
          }

          &__text {
            p {
              margin: 0;
              margin-top: 10px;
              font-size: 13px;
              line-height: 18px;
            }
          }

          &__btn {
            display: inline-flex;
            align-items: center;
            margin-top: 30px;

            i {
              font-size: 7px;
              margin-left: 5px;
            }
          }
        }
      }
    }

    &-9 {
      padding: 3em 0;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
      }

      &-title {
        margin-bottom: 20px;
        color: $primary;

        p {
          max-width: 60%;

          @include responsive-down(tablet) {
            max-width: 100%;
          }
        }
      }

      &-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        width: calc(100% + 30px);

        @include responsive-down(tablet) {
          width: calc(100% + 20px);
        }

        .item {
          width: calc(100% / 4 - 30px);
          display: flex;
          align-items: center;
          margin: 15px;

          @include responsive-down(tablet) {
            margin: 10px;
            width: calc(100% / 2 - 20px);
          }

          & > div {
            display: flex;
            flex-direction: column;
          }

          &__img {
            width: 40px;
            display: block;
            margin-bottom: 0;
          }

          &__link {
            font-weight: 700;
            margin: 1em 0;
            color: $primary;

            &:hover {
              @include responsive-up(tablet) {
                text-decoration: underline;
              }
            }
          }

          &:nth-child(6) {
            border-right: none;
          }

          &:nth-child(12) {
            border-right: none;
          }

          &:nth-child(2n) {
            @include responsive-down(tablet) {
              border-right: none;
            }
          }
        }
      }
    }

    &-10 {
      padding: 3em 0;

      &-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary;
        }

        .training__seo-content {
          display: flex;
          flex-wrap: wrap;
          width: calc(100% + 40px);
          margin: 0 -20px;

          @include responsive-down(tablet) {
            width: 100%;
            margin: 0;
          }

          p {
            width: calc(50% - 40px);
            margin: 20px;

            @include responsive-down(tablet) {
              width: 100%;
              margin: 1em 0 0;
            }
          }
        }
      }

      &-link {
        display: inline-block;
        margin-top: 20px;
      }
    }

    &-11 {
      margin: 3em 0;
      padding: 3em;
      background-color: $light-grey;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
        margin: 1.5em 0;
      }

      &-content {
        width: 100%;
        max-width: 400px;
        position: relative;

        @include responsive-up(desktop-large) {
          max-width: 450px;
        }

        @include responsive-down(tablet) {
          padding-right: 30px;
        }

        &-title {
          color: $primary;
        }

        &-link {
          i {
            font-size: 7px;
          }
        }

        .icon {
          display: none;

          &-input {
            width: 15px;
            height: 15px;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 5;
            opacity: 0;
          }

          &-cross {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: plus-animation-reverse 0.5s ease-out forwards;

            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              border-radius: 4px;
              background: $primary-blue;
            }

            &::before {
              width: 1px;
              height: 17px;
            }

            &::after {
              width: 17px;
              height: 1px;
            }
          }
        }
      }

      &-form {
        width: 100%;
        display: flex;

        @include responsive-down(tablet-extra-large) {
          flex-wrap: wrap;
        }

        & > h2 {
          display: none;
        }

        .forms {
          display: flex;
          flex-wrap: wrap;

          &__group {
            width: calc(50% - 12.5px);
            position: relative;

            @include responsive-down(mobile-extra-large) {
              width: 100%;
            }

            &:nth-child(2n+1) {
              margin-right: 25px;

              @include responsive-down(mobile-extra-large) {
                margin-right: 0;
              }
            }

            select {
              width: 100%;
            }

            .icon-general-arrow-down {
              position: absolute;
              right: 17px;
              font-size: 13px;
              top: calc(50% - 6.5px);
              color: $primary;
              z-index: 7;
            }

            &-button {
              margin-right: 0 !important;
            }

            &--hidden {
              display: none;
            }

            &--select-multiple {
              width: 100%;
              margin: 8px 0;
              margin-right: 0 !important;

              select {
                background-color: $secondary-light03;
                width: 100%;
                position: relative;

                &::after {
                  display: none;
                }
              }

              span.select2-selection.select2-selection--multiple {
                position: relative;

                &::before {
                  content: "\e902";
                  font-family: $icomoon;
                  color: $primary;
                  position: absolute;
                  top: 16px;
                  right: 12px;
                  font-weight: 700;
                  font-size: 14px;
                }
              }

              .select2-container--default.select2-container--focus .select2-selection--multiple,
              .select2-container--default .select2-selection--multiple {
                border: none !important;
              }

              .select2-container--default .select2-selection--multiple {
                padding: 12px 20px 12px 14px !important;
                font-size: 12px;
                font-weight: 300;
              }

              .select2-selection__rendered {
                padding: 0 !important;
              }

              .select2-container--default .select2-selection--multiple .select2-selection__choice {
                margin-left: 0 !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                margin-right: 5px;
                border: none !important;
                background-color: $light-grey;
              }

              .select2-selection__choice button {
                margin-top: 0;
                height: 100%;
                border: none;
                padding: 0 4px 0 6px;

                @include responsive-down(tablet) {
                  width: inherit;
                }
              }

              .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
                font-size: 11px;
                color: $primary;
                font-weight: 500;
              }

              .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
                margin-bottom: 0 !important;
              }

              span.select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
              span.select2.select2-container.select2-container--default.select2-container--focus,
              span.select2.select2-container.select2-container--default.select2-container--below {
                width: 100% !important;
              }
            }
          }
        }

        label {
          display: block;
          margin-bottom: -4px;

          &.forms__label--select {
            display: none;
          }
        }

        select {
          padding: 17px 16px 17px;
          margin: 8px 0;
          margin-right: 2.5%;
          font-size: 12px;
          color: $primary;
          line-height: 1.15;

          &::after {
            content: "/e902";
            font-family: $icomoon;
          }
        }

        button {
          font-size: 10px;
          letter-spacing: 2px;
          border: 0;
          padding: 12px 30px;
          border-radius: 3px;
          text-decoration: none;
          display: inline-block;
          text-transform: uppercase;
          background-color: $primary-blue;
          color: $white;
          margin-top: 20px;
          float: right;

          @include responsive-down(tablet) {
            width: 100%;
          }

          @include transition (all 0.2s ease 0s);

          i {
            font-size: 7px;
          }

          &:hover {
            @include responsive-up(tablet) {
              background-color: $primary;
              cursor: pointer;
            }
          }
        }

        .form {
          width: 60%;

          @include responsive-down(desktop-small) {
            width: 65%;
          }

          @include responsive-down(desktop-extra-small) {
            width: 100%;
          }

          .form-legal {
            width: 75%;

            @include responsive-down(tablet) {
              width: 100%;
            }

            &__text {
              font-size: 10px;
              line-height: 15px;
              margin-bottom: 0;
            }
          }
        }

        .text {
          width: 30%;
          margin-right: 10%;

          @include responsive-down(desktop-small) {
            width: 25%;
            margin-right: 10%;
          }

          @include responsive-down(desktop-extra-small) {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      &--training {
        margin: 3em 0 0;
        padding: 6em 3em;

        @include responsive-down(mobile-extra-large) {
          padding: 3em 0;
          margin: 1.5em 0 0;
        }

        .flexible__type-11-form {
          @include responsive-down(desktop-extra-small) {
            flex-direction: column;
          }

          .text {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: $primary;
            }
          }

          .form {
            .forms {
              margin-top: 0;

              &__group {
                @include responsive-down(mobile-extra-large) {
                  width: 100%;
                  margin-right: 0;
                }

                input[type="textarea"] {
                  width: 100%;
                }

                &--textarea {
                  width: 100%;
                  margin-right: 0 !important;
                }

                &-button {
                  width: 100%;
                  margin-right: 0;
                }
              }
            }

            // select {
            //   padding: 24px 16px 10px;
            // }
          }
        }
      }

      #masLandingHealthPromotion,
      #masPremiumRecognition {
        .forms__group--legal {
          label {
            color: $black;
          }
        }
      }
    }

    &-12 {
      position: relative;
      padding: 3em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;

      @include responsive-down(tablet) {
        padding: 0;
        margin: 1.5em 0;
      }

      video {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }

      &-video {
        width: 100%;
        height: auto;

        video {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      &-img {
        position: absolute;
        width: 100%;
        height: calc(100% - 6em);
        left: 0;
        top: 3em;
        overflow: hidden;

        @include responsive-down(tablet) {
          top: 0;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &-play {
        position: absolute;
        width: 50px;
        height: 50px;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        cursor: pointer;

        .stroke-solid {
          stroke-dashoffset: 0;
          stroke-dasharray: 300;
          stroke-width: 4px;
          transition:
            stroke-dashoffset 1s ease,
            opacity 1s ease;
        }

        .icon {
          transform: scale(0.8);
          transform-origin: 50% 50%;
          transition: transform 500ms ease-out;
        }

        // Hover
        #play:hover {
          .stroke-solid {
            opacity: 1;
            stroke-dashoffset: 300;
          }

          .icon {
            transform: scale(1);
            transition: transform 500ms ease-out;
          }
        }
      }
    }

    &-13 {
      padding: 3em 0;
      position: relative;
      margin: 0 auto;
      width: calc(100% - 10em);

      @include responsive-down(tablet) {
        width: 90%;
        padding: 1.5em 0;
      }

      &-slider {
        &-figure {
          width: 100% !important;
          margin: 0;
        }

        &-slide {
          width: 100%;

          img {
            width: 100%;
            max-height: 60vh;
            object-fit: cover;
          }
        }
      }

      &-nav {
        i {
          color: $primary-blue;
          cursor: pointer;
          font-weight: 700;
          font-size: 20px;
          position: absolute;
          top: 3em;
          height: calc(100% - 6em);
          display: flex;
          justify-self: center;
          align-items: center;

          @include responsive-down(tablet) {
            font-size: 16px;
          }

          &:first-child {
            left: -3em;

            @include responsive-down(tablet) {
              left: -1.5em;
            }
          }

          &:last-child {
            right: -3em;

            @include responsive-down(tablet) {
              right: -1.5em;
            }
          }
        }
      }
    }

    &-14 {
      padding: 3em 0;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 40px);
      margin: 0 -20px;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
        width: 100%;
        margin: 0;
      }

      &-content {
        width: calc(100% / 2 - 40px);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        margin: 20px;
        background-color: $primary-blue;
        padding: 40px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        color: white;

        @include responsive-down(tablet) {
          width: 100%;
          margin: 10px 0;
          padding: 40px 20px;
        }

        &:nth-child(2n) {
          background-color: $primary;
        }

        i {
          margin-bottom: 20px;
          display: inline-block;
          font-size: 20px;
        }
      }

      &-title {
        margin-bottom: 20px;
        margin-left: 10px;
      }

      &-list {
        columns: 2;
        column-gap: 40px;
        width: 95%;
        margin: 0 auto;

        @include responsive-down(tablet-extra-large) {
          columns: 1;
        }

        @include responsive-down(tablet-extra-small) {
          columns: 1;
          width: 90%;
          margin: 0 auto;
        }

        li {
          &::marker {
            color: $white;
          }
        }
      }
    }

    &-15 {
      padding: 3em 0;
      background: $light-grey;
      text-align: center;

      &-content {
        max-width: 40%;
        margin: 0 auto;

        @include responsive-down(desktop) {
          max-width: 50%;
        }

        @include responsive-down(tablet-extra-large) {
          max-width: 80%;
        }

        @include responsive-down(mobile-extra-large) {
          max-width: 100%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-align: center;
          color: $primary;
          margin-top: 0;
        }

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          text-decoration: underline;

          &:hover {
            @include responsive-up(tablet) {
              text-decoration: none;
            }
          }
        }
      }
    }

    &-16 {
      padding: 3em 0;

      &-icon {
        width: 30px;
      }

      &-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 10px 0 20px;
          color: $primary;
        }

        a {
          margin: 20px 40px 0 0;
          display: inline-flex;
          align-items: center;

          @include responsive-down(mobile) {
            margin: 20px 20px 0 0;
          }

          &:last-child {
            margin-right: none;
          }
        }
      }

      .file {
        &__icon {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 20px;
          position: relative;
          z-index: -1;
          margin-right: 5px;

          &-arrow {
            display: block;
            background: $primary-blue;
            width: 2px;
            height: 18px;
            margin: auto;
            position: relative;
            bottom: 4px;
            left: 0;
            border-radius: 9px;

            &::after {
              position: absolute;
              content: '';
              width: 2px;
              height: 8px;
              transform: rotate(45deg);
              background: $primary-blue;
              bottom: 0;
              left: 3px;
              border-radius: 9px;
            }

            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 8px;
              transform: rotate(-45deg);
              background: $primary-blue;
              bottom: 0;
              right: 3px;
              border-radius: 9px;
            }
          }

          &-frame {
            display: block;
            width: 16px;
            height: 2px;
            background: $primary-blue;
            margin: 0 auto 0 auto;
            border-radius: 9px;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            position: absolute;
            bottom: 0;
            left: calc(50% - 8px);
          }

          &.round {
            .file__icon-arrow {
              width: 100px;
              height: 100px;
              animation: none;
              border-radius: 50%;
              background: transparent;
              border: solid 5px $primary-blue;
              box-sizing: border-box;
              top: 21px;

              &::after,
              &::before {
                visibility: hidden;
              }
            }

            .file__icon-frame {
              transform: rotate(-45deg);
              animation: none;
              top: 70px;
              left: 25px;
              width: 60px;

              &::before {
                content: '';
                width: 20px;
                height: 5px;
                background: $primary-blue;
                transform: rotate(90deg);
                position: absolute;
                left: -8px;
                top: -10px;
                border-radius: 9px;
              }

              &::after {
                transform: rotate(0);
                top: 0;
                right: 0;
              }
            }
          }
        }
      }

      ul {
        li {
          a {
            margin: 0;
          }
        }
      }
    }

    &-17 {
      padding: 3em 0;

      @include responsive-down(tablet) {
        padding: 1.5em 0;
      }

      &-title {
        margin-bottom: 40px;
        color: $primary;

        @include responsive-down(tablet) {
          margin-bottom: 20px;
        }
      }

      &-opinions {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;

        @include responsive-down(tablet) {
          margin: 0;
        }

        .opinion {
          width: calc(100% / 3 - 40px);
          margin: 20px;

          @include responsive-down(tablet) {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
          }

          &__title {
            color: $primary-blue;
            font-weight: 700;
            margin-bottom: 5px;
          }

          &__job {
            color: $primary-blue;
            font-style: italic;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 0.25em;
          }

          &__company {
            color: $secondary-grey;
            margin-top: 0;
          }

          &__text {
            font-style: italic;
            font-size: 14px;
          }

          &:last-child {
            @include responsive-down(tablet) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-19 {
      padding: 30px 0;

      &__title {
        color: $primary;
        font-size: 25px;
        margin: 30px 0 60px;

        @include responsive-down(tablet) {
          font-size: 22px;
        }

        @include responsive-down(mobile-large) {
          font-size: 20px;
          margin: 30px 0;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -30px 0 0;

        @include responsive-down(mobile-large) {
          margin: 0 -10px 0 0;
        }
      }

      .cardcourse {
        display: flex;
        flex-direction: column;
        box-shadow: 0 3px 6px #0000004d;
        border-radius: 10px 10px 10px 0;
        margin: 0 30px 30px 0;
        text-decoration: none !important;
        width: calc(100% / 5 - 30px);

        @include responsive-down(desktop) {
          width: calc(100% / 4 - 30px);
        }

        @include responsive-down(tablet-extra-large) {
          width: calc(100% / 3 - 30px);
        }

        @include responsive-down(tablet) {
          width: calc(100% / 2 - 30px);
        }

        @include responsive-down(mobile-large) {
          width: calc(100% / 2 - 10px);
          margin: 0 10px 30px 0;
        }

        &__img {
          width: 100%;
          height: 230px;
          overflow: hidden;
          border-radius: 10px 10px 0 0;

          @include responsive-down(mobile-large) {
            height: 110px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include transition(all 0.3s ease-in);
          }
        }

        &__content {
          padding: 15px 10px 30px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 160px;
          text-decoration: none;

          @include responsive-down(mobile-large) {
            padding: 10px 10px 20px;
            min-height: 135px;
          }

          p {
            margin: 0;
          }
        }

        &__tax {
          font-size: 14px;
          color: $primary-blue;
          text-transform: uppercase;
          text-decoration: none;

          @include responsive-down(mobile-large) {
            font-size: 11px;
          }
        }

        &__title {
          font-size: 22px;
          line-height: 1.2;
          color: $primary;
          margin: 5px 0 0 !important;
          font-weight: 700;
          text-decoration: none;

          @include responsive-down(tablet-large) {
            font-size: 20px;
          }

          @include responsive-down(mobile-large) {
            font-size: 16px;
          }
        }

        .link {
          margin: auto 0 0;
          font-size: 12px;
          text-decoration: none;

          @include responsive-down(mobile-large) {
            font-size: 9px;
          }
        }

        &:hover {
          @include responsive-up(tablet-extra-large) {
            img {
              transform: scale(1.2);

              @include transition(all 0.3s ease-in);
            }
          }
        }
      }

      .tns-nav {
        display: flex;
        justify-content: center;

        button {
          width: 5px;
          height: 5px;
          padding: 20px 5px;
          margin: 0 2px;
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            padding: 0;
            background-color: $secondary-grey;
            border-radius: 50px;
          }

          &.tns-nav-active {
            &::before {
              background-color: $primary-blue;
            }
          }
        }
      }
    }

    &-21 {
      width: auto;
      padding: 80px 0;
      position: relative;

      .container {
        max-width: 1350px;

        @include responsive-down(tablet-small) {
          padding: 0;
        }
      }

      .tns-nav {
        display: flex;
        justify-content: center;
        margin: 50px 0 0;

        button {
          padding: 20px 5px;
          margin: 0 4px;
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            padding: 0;
            background-color: $secondary-grey;
            border-radius: 50px;
          }

          &.tns-nav-active {
            &::before {
              background-color: $primary-blue;
            }
          }
        }
      }

      .tns-controls {
        button {
          position: absolute;
          top: 47%;
          z-index: 9;
          background-color: transparent;
          color: transparent;
          font-size: 0;
          display: inline-block;
          padding: 0;

          &::before {
            content: "\e902";
            font-family: $icomoon;
            position: relative;
            font-size: 30px;
            color: $primary;
          }

          &[data-controls="prev"] {
            left: 4%;
            transform: rotate(90deg);
          }

          &[data-controls="next"] {
            right: 4%;
            transform: rotate(270deg);
          }
        }
      }

      &__title {
        font-size: 30px;
        margin: 0 0 50px;
        font-weight: 700;
        color: $primary;
        text-align: center;

        @include responsive-down(tablet-large) {
          font-size: 25px;
        }

        @include responsive-down(tablet-small) {
          padding: 0 20px;
          font-size: 20px;
          margin: 0 0 20px;
        }
      }

      &__item {
        &:first-child {
          @include responsive-down(tablet-small) {
            margin: 0 0 0 20px;
          }
        }
      }

      &__itemcontent {
        display: flex;
        gap: 100px;
        justify-content: center;
        align-items: stretch;

        @include responsive-down(tablet-large) {
          gap: 30px;
        }

        @include responsive-down(tablet-small) {
          flex-direction: column;
          margin: 0 20px 0 0;
          gap: 20px;
        }
      }

      &__media {
        border-radius: 10px;
        overflow: hidden;
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include responsive-down(tablet-large) {
          gap: 30px;
          min-height: inherit;
        }

        @include responsive-down(tablet-small) {
          width: 100%;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background: #144689 0% 0% no-repeat padding-box;
          mix-blend-mode: multiply;
          opacity: 0.5;
          position: absolute;
          left: 0;
          top: 0;
        }

        img,
        frame,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          min-height: 170px;
        }

        .item-play {
          width: 60px;
          height: 60px;
          border-radius: 50px;
          background-color: #ffffffb7;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;

          @include responsive-down(tablet-small) {
            width: 30px;
            height: 30px;
          }

          i {
            font-size: 25px;

            @include responsive-down(tablet-small) {
              font-size: 14px;
            }
          }

          &__container {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &__content {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto 0;
        padding: 80px 0;
        gap: 50px;

        @include responsive-down(tablet-small) {
          width: 100%;
          padding: 0;
          gap: 20px;
        }

        p,
        h3,
        h4 {
          font-size: 28px;
          line-height: 1.3;
          font-weight: 500;
          margin: 0;

          @include responsive-down(tablet-large) {
            font-size: 24px;
          }

          @include responsive-down(tablet-small) {
            font-size: 16px;
          }
        }
      }

      &__date {
        font-size: 18px !important;
        margin: auto 0 0;
        font-weight: 300 !important;

        @include responsive-down(tablet-small) {
          font-size: 12px !important;
        }

        strong {
          font-weight: 500 !important;
        }
      }
    }

    &-access {
      display: flex;
      justify-content: flex-end;
      padding: 1.5em 0 0;
      margin-bottom: -1.5em;

      @include responsive-down(tablet) {
        display: none;
      }

      &__container {
        display: flex;
        flex-direction: column;

        &--mobile {
          display: none;

          @include responsive-down(tablet) {
            display: flex;
            width: auto;
            align-items: flex-start;
          }
        }

        a,
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &__text {
        color: $primary;
        letter-spacing: 2px;
        display: inline-block;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &--small {
      width: 80%;
      margin: 0 auto;

      @include responsive-down(tablet) {
        width: 100%;
      }
    }
  }
}

@keyframes plus-animation {
  0% {
    transform: rotate(0) scale(1);
  }

  20% {
    transform: rotate(60deg) scale(0.93);
  }

  55% {
    transform: rotate(35deg) scale(0.97);
  }

  80% {
    transform: rotate(48deg) scale(0.94);
  }

  100% {
    transform: rotate(45deg) scale(0.95);
  }
}

@keyframes plus-animation-reverse {
  0% {
    transform: rotate(45deg) scale(0.95);
  }

  20% {
    transform: rotate(-15deg);
  }

  55% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes move {
  from {
    top: 0;
  }

  to {
    top: 15px;
  }
}

// Spin animation
@keyframes spin {
  to { transform: rotate(360deg); }
}

.pswp {
  &__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
  }

  &__counter {
    color: white;
    font-size: 12px;
  }

  &__button {
    width: 15px;
    height: 15px;
    padding: 0;
    background-color: transparent;
    border: none;

    svg {
      fill: $white;
    }

    i {
      color: $white;
    }

    &--arrow {
      &--left {
        position: absolute;
        left: 20px;
        top: calc(50% - 5px);
      }

      &--right {
        position: absolute;
        right: 20px;
        top: calc(50% - 5px);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.page-id-72 {
  .flexible__type-11 {
    cursor: pointer;
    margin: 0;
    padding: 2em 3em;

    @include responsive-down(tablet) {
      padding: 2em 0;
    }

    &-content {
      &-text {
        display: none;
      }

      .icon {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 7px;

        @include responsive-down(tablet) {
          top: 3px;
        }
      }
    }

    & ~ .flexible__type-11 {
      &::before {
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.05), transparent);
      }
    }

    &-form {
      display: none;

      .forms__group--legal label {
        color: inherit;
      }

      .forms__group--legal .forms__input {
        width: 12px;
        height: 12px;
        margin: 0;
        margin-right: 5px;
        cursor: pointer;
        background-color: $white;
        border: 1px solid $primary;
        padding: 0;
        border-radius: 2px;
      }
    }
  }

  .flexible__type-11.container--opened {
    .flexible__type-11-content {
      &-title {
        border-bottom: 1px solid $primary;
        padding-bottom: 20px;
      }

      .icon-cross {
        animation: plus-animation 0.5s ease-out forwards;
      }
    }

    .flexible__type-11-form {
      margin-top: 30px;
      display: flex;

      @include responsive-down(desktop-extra-small) {
        flex-direction: column;
      }

      @include responsive-down(mobile) {
        margin-top: 0;
      }
    }
  }
}

.tax-mas_cpt_specialties_category {
  .flexible__type-1 {
    &-image {
      justify-content: center;

      img {
        width: 40%;
        justify-content: center;

        @include responsive-down(mobile) {
          width: 60%;
        }
      }
    }
  }
}

body.page-template-page_home {
  .flexible__type-5 {
    padding: 5em 0;

    @include responsive-down(mobile-large) {
      padding: 3em 0;
    }
  }
}
