/* PAGE: BLOG
--------------------------------------------------------------------------------------------------------------------- */
.blog {
  &-nav {
    background-color: $secondary-light1;
    position: fixed;
    height: 50px;
    width: 100%;
    z-index: 4;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;

      @include responsive-down(mobile-extra-large) {
        background-color: transparent;
        display: none;
      }
    }

    &__title {
      font-size: 16px;
      z-index: 4;
      color: $primary;
      font-weight: 700;

      @include responsive-down(tablet) {
        display: none;
      }
    }

    &__list {
      display: inline-flex;
      align-items: center;
    }

    &__item {
      display: inline-flex;
      text-transform: initial;
      align-items: center;
      padding: 0 0 0 60px;
      font-size: 12px;
      margin: 0;

      @include responsive-down(tablet) {
        padding: 0 50px 0 0;
      }

      @include responsive-down(tablet-small) {
        padding: 0 40px 0 0;
      }

      a {
        font-size: 12px;
        font-weight: 700;
        color: $primary;

        &:hover {
          @include responsive-up(tablet) {
            color: $primary-headertop;
          }
        }
      }

      img {
        width: auto;
        height: 20px;
        margin-right: 7px;
      }
    }

    &--fixed {
      display: none;
      width: 100%;
      height: 50px;
      background-color: $secondary-light1;
      position: fixed;
      top: 57px;
      padding: 0 20px;
      align-content: center;
      align-items: center;
      z-index: 1;

      i {
        color: $primary-blue;
      }

      @include responsive-down(mobile-extra-large) {
        display: inline-flex;
        justify-content: space-between;
      }

      .text {
        margin: 0;
        color: $primary;
        font-weight: 700;
      }
    }

    &--open {
      height: auto;

      .container {
        display: flex;
      }

      .blog-nav__list {
        flex-direction: column;
        margin: 0;
        display: inline-flex;
        background-color: #f7f6f3;
        width: 100%;
        align-items: flex-start;
        right: 0;
        left: 0;
        padding: 0 17px;
        position: absolute;
        top: 50px;
        border-top: 1px solid $secondary-grey;
      }

      .blog-nav__item.blog-nav__item {
        padding: 10px 0;
      }
    }
  }

  &-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    padding: 100px 0 60px;
    margin: auto;

    @include responsive-down(mobile) {
      padding: 100px 0 40px;
    }

    @include responsive-down(mobile) {
      max-width: 100%;
      align-items: flex-start;
      padding: 80px 0 30px;
    }

    &__title {
      text-align: center;
      color: $primary;
      font-size: 30px;
      margin: 0;
      line-height: 32px;

      @include responsive-down(mobile-extra-large) {
        font-size: 28px;
      }

      @include responsive-down(mobile) {
        font-size: 25px;
        text-align: left;
        max-width: 300px;
      }

      @include responsive-down(mobile-small) {
        font-size: 22px;
        max-width: 250px;
      }
    }

    &__subtitle {
      color: $primary;
      font-size: 15px;
      line-height: 20px;
      margin: 15px 0;
      text-align: center;

      @include responsive-down(mobile-small) {
        text-align: left;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    @include responsive-down(mobile-extra-large) {
      margin: 0 -10px;
    }

    @include responsive-down(mobile-large) {
      margin: 0;
    }

    @include responsive-down(mobile) {
      padding: 0;
    }

    &__item {
      box-shadow: $box-shadow-generic;
      width: calc(100% / 3 - 40px);
      height: auto;
      border-radius: 20px;
      border-bottom-left-radius: 0;
      margin: 0 20px 40px;
      overflow: hidden;

      @include responsive-down(tablet) {
        width: calc(100% / 2 - 40px);
        margin: 0 20px 40px;
      }

      @include responsive-down(mobile-extra-large) {
        width: calc(100% / 2 - 20px);
        margin: 0 10px 20px;
      }

      @include responsive-down(mobile-large) {
        width: 100%;
        margin: 0 0 20px;
      }

      &:hover {
        @include responsive-up(tablet) {
          text-decoration: none;

          .blog-list__itemimg {
            img {
              transform: scale(1.1);

              @include transition (all 0.5s ease 0s);
            }
          }
        }
      }

      a {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: white;
        border-radius: 20px;
        height: 100%;
        text-decoration: none;
      }
    }

    &__itemimg {
      overflow: hidden;

      img {
        width: 100%;
        display: grid;
        max-height: 220px;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        @include transition (all 0.5s ease 0s);

        @include responsive-down(mobile-small) {
          max-height: 180px;
        }
      }
    }

    &__itemtext {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      @include responsive-down(mobile-small) {
        padding: 15px;
      }

      .title {
        font-size: 18px;
        line-height: 1.3;
        margin: 5px 0 20px;
        color: $primary;

        @include responsive-down(mobile-small) {
          font-size: 16px;
        }
      }

      .link {
        font-size: 12px;
        display: inline-flex;
        align-items: baseline;
        align-content: center;
        text-decoration: none;
        position: relative;

        @include transition (all 0.2s ease-in);

        &:hover {
          @include responsive-up(tablet) {
            color: $primary;
          }
        }

        i {
          font-size: 9px;
          margin-left: 5px;
        }
      }
    }
  }

  .blogpost-footer__nav {
    padding: 0 0 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    border: 0;
  }
}

body.single {
  .blog-nav {
    .container {
      justify-content: space-between;

      @include responsive-down(tablet-extra-small) {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        justify-content: center;
        width: 100%;
        height: 50px;
        max-width: 100%;
        margin: initial;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__list {
      &:first-child {
        @include responsive-down(tablet-extra-small) {
          margin-left: 150px;
        }

        @include responsive-down(mobile-extra-large) {
          margin-left: 350px;
        }

        @include responsive-down(mobile) {
          margin-left: 500px;
        }
      }
    }

    &__item {
      padding: 0 30px;

      @include responsive-down(desktop) {
        padding: 0 10px;
      }

      @include responsive-down(tablet) {
        padding: 0;
      }
    }
  }
}
