/* Block: Cards area
--------------------------------------------------------------------------------------------------------------------- */
.cardsarea {
  min-height: 180px;
  padding: 50px;
  display: inline-flex;
  flex-direction: column;
  box-shadow: $box-shadow-generic;
  border-radius: 10px;
  border-bottom-left-radius: 0;

  @include transition(all 0.3s ease-out);

  @include responsive-down(desktop-large) {
    padding: 25px;
  }

  @include responsive-down(mobile) {
    padding: 10px 20px;
    min-height: 150px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: $primary;
    margin: 0 0 20px;
  }

  &__description {
    margin: auto 0 10px;
    font-size: 14px;
    line-height: 18px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__link {
    font-size: 12px;
    width: max-content;
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      transform: translateY(-10px);
      background-color: $primary;
      text-decoration: none;

      @include transition(all 0.3s ease-out);

      .cardsarea__title,
      .cardsarea__description,
      .cardsarea__icon {
        color: $white;
      }

      .cardsarea__link {
        color: $primary-blue;
      }
    }
  }

  &--icon {
    display: inline-block;

    .cardsarea {
      &__icon {
        font-size: 35px;
        color: $primary-blue;
        margin-bottom: 10px;
      }

      &__title {
        margin: 20px 0 6px;

        @include responsive-down(mobile) {
          margin: 5px 0 0;
        }
      }

      &__description {
        margin: 0 0 10px;

        @include responsive-down(mobile) {
          margin: 0;

          p {
            margin: 5px 0;
          }
        }
      }
    }
  }
}
