.areahome {
  &__slider {
    height: 450px;
    overflow: hidden;

    @include responsive-down(mobile) {
      height: 350px;
    }

    .tns-outer {
      position: relative;
      height: 100%;

      .tns-nav {
        position: absolute;
        bottom: 25%;
        left: 50px;
        z-index: 8;

        @include responsive-down(tablet-extra-large) {
          left: 30px;
        }

        @include responsive-down(mobile) {
          left: 15px;
        }

        button {
          width: 40px;
          height: 3px;
          margin-right: 10px;
          padding: 0;
          background-color: rgba(255, 255, 255, 0.5);
          overflow: hidden;
          position: relative;

          &:hover {
            background-color: $white;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: $white;
            width: 0;
            height: 4px;
          }

          &.tns-nav-active {
            &::after {
              animation: progresscolor 5s ease-in;
              width: 100%;
            }
          }
        }
      }

      .tns-ovh,
      .tns-inner,
      .tns-slider {
        height: 100%;
      }
    }

    .tns-liveregion {
      display: none;
    }
  }

  &__slide {
    position: relative;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 100%;
    }
  }

  &__sliderimg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &__slidertext {
    width: 35%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include responsive-down(tablet-extra-large) {
      width: 50%;
      left: 30px;
    }

    @include responsive-down(tablet) {
      width: 60%;
    }

    @include responsive-down(tablet-extra-small) {
      width: 75%;
    }

    @include responsive-down(mobile) {
      left: 15px;
    }

    @include responsive-down(mobile-small) {
      width: 90%;
    }

    h3 {
      font-size: 35px;
      color: $white;

      @include responsive-down(tablet-extra-small) {
        font-size: 25px;
      }

      .title {
        -webkit-animation: mainfadein 1.5s forwards;
        -o-animation: mainfadein 1.5s forwards;
        animation: mainfadein 1.5s forwards;
        animation-delay: 1.6s;
        opacity: 0;
      }
    }
  }

  &__quickpost {
    display: flex;
    align-items: flex-start;
    margin: 50px 0;

    @include responsive-down (tablet) {
      flex-wrap: wrap;
    }

    @include responsive-down(mobile) {
      margin: 30px 0;
    }
  }

  &__postscontainer {
    width: calc(100% - 350px);
    margin-left: 100px;
    margin-right: -50px;

    @include responsive-down (tablet-extra-large) {
      width: calc(100% - 300px);
      margin-left: 50px;
    }

    @include responsive-down (tablet) {
      width: calc(100% + 30px);
      margin-left: 0;
      margin-top: 50px;
    }

    @include responsive-down (mobile) {
      margin-top: 30px;
    }

    .tns-slider {
      .areahome__lastnews {
        a {
          img {
            width: 100%;
          }
        }
      }
    }

    &--single {
      width: 100%;
      margin: 50px 0;
    }
  }

  &__poststitle {
    font-size: 25px;
    color: $primary;
    margin-bottom: 25px;

    @include responsive-down(mobile) {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
}
